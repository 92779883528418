import ReactGA from 'react-ga4';
import { selectAssessmentState } from '../../../features/assessment/assessmentSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import HasSleepApnea from './HasSleepApnea';
import NoSleepApnea from './NoApnea';
import HighRisk from './HighRisk';
import SaveApneaAssessment from './SaveApneaAssessment';
import { usePostHog } from 'posthog-js/react';

const AssessmentResult = () => {
    const assessmentState = useSelector(selectAssessmentState);
    const posthog = usePostHog();

    useEffect(() => {
        if (
            Array.isArray(assessmentState.answers.highRiskSelections) &&
            assessmentState.answers.highRiskSelections.length > 0 &&
            assessmentState.answers.highRiskSelections.indexOf('NONE') < 0 &&
            assessmentState.hasSleepApnea
        ) {
            posthog?.capture('Received Assessment Result', { result: 'Apnea - high risk' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea - high risk',
            });
        } else if (assessmentState.hasSleepApnea) {
            posthog?.capture('Received Assessment Result', { result: 'Apnea' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea',
            });
        } else {
            posthog?.capture('Received Assessment Result', { result: 'No Apnea' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'No Apnea',
            });
        }
        // eslint-disable-next-line
    }, []);

    // already diagnosed - go to account creation
    if (
        (assessmentState.showAccountCreationScreen || assessmentState.skipResultScreen) &&
        assessmentState.bmi < 45
    )
        return <SaveApneaAssessment />;

    if (!assessmentState.hasSleepApnea) {
        return <NoSleepApnea />;
    }

    if (
        (Array.isArray(assessmentState.answers.highRiskSelections) &&
            assessmentState.answers.highRiskSelections.length > 0 &&
            assessmentState.answers.highRiskSelections.indexOf('NONE') < 0) ||
        assessmentState.bmi >= 45
    ) {
        return <HighRisk highRiskSelections={assessmentState.answers.highRiskSelections} />;
    }

    //Toggles showAccountCreationScreen afterwards to return to line 42
    if (assessmentState.hasSleepApnea) {
        return <HasSleepApnea />;
    }
};

export default AssessmentResult;
