import { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useAddToCartMutation, useRemoveFromCartMutation } from '../../app/services/cart';
import { ReactComponent as InsuranceIcon } from '../../images/icons/insurance.svg';
import { getFormattedVariationOptionsBySku, formatSquarePrice } from '../../utils/constants';

export const CartProduct = ({ product, removeActions = false }) => {
    const { usingInsurance } = useSelector((state) => state.cart);
    const { catalogItems, catalogOptions } = useSelector((state) => state.catalog);

    //Collect data on item options if applicable as value/name
    const formattedData = getFormattedVariationOptionsBySku(
        product.sku,
        catalogItems,
        catalogOptions,
    );

    const isRequiredConsult =
        product.name.toLowerCase() === 'required consult' || product.sku === 'REQCONSULT';

    const isDeposit = product.sku === 'VISITDEPOSIT';

    const notRemoveable = isRequiredConsult || isDeposit || product.sku === 'GEMCOACH';

    const [canAdjustQuantity] = useState(!notRemoveable && !product.single);
    const [addToCart] = useAddToCartMutation();
    const [removeFromCart] = useRemoveFromCartMutation();

    return (
        <li className="flex items-start text-left">
            <img className="w-1/3 pr-4" src={product.imageUrl} alt="" />
            <div className="flex-1">
                <h6 className="mb-3 font-heading text-2xl font-bold leading-none xl:text-3xl">
                    {isRequiredConsult ? 'Virtual Visit' : product.name}
                </h6>

                {formattedData.map((d, idx) => (
                    <p key={`opt-idx-${idx}`} className="mb-1 text-xs leading-none xl:text-sm">
                        <b>{d.name}:</b> {d.value}
                    </p>
                ))}

                {usingInsurance && !isDeposit && (
                    <div>
                        <p className="inline-flex items-center bg-purple-lightest p-2 font-heading">
                            <span className="text-sm tracking-widest">Submitted to Insurance</span>
                            <InsuranceIcon className="ml-2 w-4" />
                        </p>
                    </div>
                )}

                <p className="mb-3 mt-2 flex items-center space-x-4">
                    {(!usingInsurance || isDeposit) && (
                        <span className="mr-4 text-sm font-bold tracking-tight xl:mr-5 xl:text-base">
                            {product.price === 0 ? 'FREE' : formatSquarePrice(product.price)}
                        </span>
                    )}
                </p>

                {!removeActions && (
                    <p className="mb-5 flex items-center space-x-3">
                        {canAdjustQuantity && (
                            <>
                                <button
                                    className="relative h-7 w-7 rounded-full border-2 border-green-400"
                                    onClick={() => {
                                        removeFromCart([{ ...product, quantity: 1 }]);
                                    }}>
                                    <AiOutlineMinus className="absolute-center w-5" />
                                </button>
                                <span className="font-heading text-2xl">{product.quantity}</span>
                                <button
                                    className="relative h-7 w-7 rounded-full border-2 border-green-400"
                                    onClick={() => {
                                        addToCart([{ ...product, quantity: 1 }]);
                                    }}>
                                    <AiOutlinePlus className="absolute-center w-5" />
                                </button>
                            </>
                        )}
                        {!notRemoveable && (
                            <button
                                className="font-bold text-green-400"
                                onClick={() => {
                                    removeFromCart([product]);
                                }}>
                                Remove
                            </button>
                        )}
                    </p>
                )}
            </div>
        </li>
    );
};
