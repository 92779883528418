import { useState } from 'react';
import {
    useAddOrUpdatePriorAuthRequirementMutation,
    useDeletePriorAuthRequirementMutation,
} from '../../../../app/services/admin/insurance';
import { orderItemCategories, priorAuthStatuses } from '../../../../utils/constants';
import Modal from '../../../Modal/Modal';
import { toast } from 'react-toastify';

function EditPriorAuthRequirements({ editingPayer, modalOpen, setEditingPayer, setModalOpen }) {
    //Local state
    const [tempPriorAuthRequirements, setTempPriorAuthRequirements] = useState([
        ...editingPayer.priorAuthRequirements,
    ]);
    const [tempRequirementId, setTempRequirementIds] = useState(0.5);

    const [addOrUpdatePriorAuthRequirement] = useAddOrUpdatePriorAuthRequirementMutation();
    const [deletePriorAuthRequirement] = useDeletePriorAuthRequirementMutation();

    function handSubmit() {
        //Make sure none of the item categories are left null
        if (
            tempPriorAuthRequirements.some(
                (requirement) =>
                    requirement.itemCategory === null || requirement.priorAuthStatus === null,
            )
        ) {
            toast.error('All item categories and statuses must be set');
            return;
        }

        //Determine the unique item categories
        const itemCategoriesInRequirements = new Set(
            tempPriorAuthRequirements.map((requirement) => requirement.itemCategory),
        );

        //Determine which item categories currently have requirements
        const originalRequirements = new Set(
            editingPayer.priorAuthRequirements.map((requirement) => requirement.itemCategory),
        );

        //Find the difference of these two sets to know which requirements to remove
        const requirementsToDelete = editingPayer.priorAuthRequirements.filter((requirement) =>
            originalRequirements
                .difference(itemCategoriesInRequirements)
                .has(requirement.itemCategory),
        );

        //Submit only if all item categories are unique
        if (itemCategoriesInRequirements.size === tempPriorAuthRequirements.length) {
            requirementsToDelete.forEach((requirement) =>
                deletePriorAuthRequirement({ id: requirement.id, payerId: editingPayer.id }),
            );

            addOrUpdatePriorAuthRequirement({
                payerId: editingPayer.id,
                priorAuthRequirements: tempPriorAuthRequirements.map((requirement) => ({
                    priorAuthStatus: requirement.priorAuthStatus,
                    itemCategory: requirement.itemCategory,
                })),
            });

            toast.success('Prior Auth Requirements Updated');
            setEditingPayer(null);
            setModalOpen(null);
        } else {
            toast.error('Cannot have two requirements with the same item category');
        }
    }

    return (
        <Modal
            showClose={true}
            isOpen={editingPayer !== null && modalOpen === 'prior-auth-requirements'}
            closeHandler={() => {
                setEditingPayer(null);
                setModalOpen(null);
            }}>
            <section className="py-4 space-y-4">
                <h3 className="font-heading text-2xl border-b border-gray-400">
                    Prior Auth Requirements
                </h3>
                {tempPriorAuthRequirements.length === 0 ? (
                    <p>No prior auth requirements set</p>
                ) : (
                    tempPriorAuthRequirements.map((requirement, index) => (
                        <div
                            className="border rounded border-[1px] border-[#d3deed] p-3 space-y-2"
                            key={'requirement-' + requirement.id}>
                            <div className="flex items-center">
                                <p className="min-w-[200px]">Item Category</p>
                                <select
                                    key={'requirement-category-' + requirement.id}
                                    className="w-full rounded-md border text-black h-full"
                                    multiple={false}
                                    defaultValue={requirement.itemCategory}
                                    placeholder="Choose an Item Category"
                                    name="itemCategory"
                                    onChange={(e) =>
                                        setTempPriorAuthRequirements((currentRequirements) =>
                                            currentRequirements.map((requirement, idx) =>
                                                idx === index
                                                    ? {
                                                          ...requirement,
                                                          itemCategory: e.target.value,
                                                      }
                                                    : requirement,
                                            ),
                                        )
                                    }>
                                    <option key="null-value" value={null}>
                                        Choose an Item Category
                                    </option>
                                    {orderItemCategories.map((opt, idx) => (
                                        <option key={idx} value={opt.value}>
                                            {opt.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <p className="min-w-[200px]">Prior Auth Requirement</p>
                                <select
                                    key={'requirement-prior-auth-' + requirement.id}
                                    className="w-full rounded-md border text-black h-full"
                                    multiple={false}
                                    defaultValue={requirement.priorAuthStatus}
                                    placeholder="Choose a Prior Auth Status"
                                    name="priorAuthStatus"
                                    onChange={(e) =>
                                        setTempPriorAuthRequirements((currentRequirements) =>
                                            currentRequirements.map((requirement, idx) =>
                                                idx === index
                                                    ? {
                                                          ...requirement,
                                                          priorAuthStatus: e.target.value,
                                                      }
                                                    : requirement,
                                            ),
                                        )
                                    }>
                                    {priorAuthStatuses.map((opt, idx) => (
                                        <option key={idx} value={opt.value}>
                                            {opt.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                className="text-red hover:text-red-hover"
                                onClick={() => {
                                    const currentRequirements = [...tempPriorAuthRequirements];
                                    currentRequirements.splice(index, 1);
                                    setTempPriorAuthRequirements(currentRequirements);
                                }}>
                                Remove
                            </button>
                        </div>
                    ))
                )}
                {tempPriorAuthRequirements.length < orderItemCategories.length && (
                    <button
                        className="border-b-2 border-gem-green font-bold"
                        onClick={(e) => {
                            e.preventDefault();
                            setTempPriorAuthRequirements([
                                ...tempPriorAuthRequirements,
                                {
                                    itemCategory: null,
                                    priorAuthStatus: null,
                                    id: tempRequirementId,
                                },
                            ]);
                            setTempRequirementIds(tempRequirementId + 1);
                        }}>
                        Add Requirement
                    </button>
                )}
            </section>
            <button
                className="btn-primary w-full"
                disabled={
                    tempPriorAuthRequirements.length === 0 &&
                    editingPayer?.priorAuthRequirements.length === 0
                }
                onClick={() => {
                    handSubmit();
                }}>
                Save Requirements
            </button>
        </Modal>
    );
}

export default EditPriorAuthRequirements;
