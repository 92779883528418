import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetAdminQueriesQuery } from '../../../app/services/admin/search';
import {
    setAvailableFilters,
    setFilterComponents,
    setStatusFilter,
} from '../../../features/admin/adminSlice';
import { adminSelectCustomStyles } from '../../../utils/constants';
import DeleteQuery from './DeleteQuery';

const Queries = ({ setPage }) => {
    const dispatch = useDispatch();
    const { data: queries, error } = useGetAdminQueriesQuery();
    const { availablePatientFilters, activePatientFilters } = useSelector((state) => state.admin);
    //Default to the query search if it has been set to allow returning to page to maintain state
    const [query, setQuery] = useState(activePatientFilters.querySearch);

    //Reset both status and careteam queries, reset query selection, remove all status filters
    const clearQuerySearch = async () => {
        dispatch(
            setStatusFilter({
                type: 'activePatientFilters',
                key: 'querySearch',
                value: null,
            }),
        );
    };

    const querySearchHandler = async () => {
        //Remove all filters and searches and reset any searches run by them
        dispatch(
            setStatusFilter([
                {
                    type: 'activePatientFilters',
                    key: 'searchTermValue',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'searchTermType',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'skip',
                    value: 0,
                },
                {
                    type: 'activePatientFilters',
                    key: 'patientProfileSearch',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'careTeam',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'insurance',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'appointments',
                    value: null,
                },
            ]),
        );

        //Set all status filters to be available
        const newAvailableFilters = availablePatientFilters.map((obj) => ({
            ...obj,
            available: true,
        }));
        dispatch(setAvailableFilters(newAvailableFilters));
        dispatch(setFilterComponents({ key: 'patientFilterComponents', value: [] }));
        setPage(0);

        //Dispatch new search and set Users per Page to match
        dispatch(
            setStatusFilter({
                type: 'activePatientFilters',
                key: 'querySearch',
                value: query,
            }),
        );
    };

    return (
        <div className="col-start-1 col-span-6 p-2">
            <p className="mb-3 font-semibold">Queries:</p>
            <div className="flex flex-wrap items-center">
                <Select
                    key={queries?.length}
                    options={queries?.map((query) => ({
                        label: query.queryName,
                        value: query.queryString,
                        id: query.id,
                        raw: query.raw,
                        userId: query.userId,
                    }))}
                    className="basis-full md:basis-1/2 pr-4"
                    onChange={setQuery}
                    placeholder={'Choose Query'}
                    styles={adminSelectCustomStyles}
                    defaultValue={null}
                    value={query}
                />
                <div className="flex items-center basis-full md:basis-1/2 mt-4 pr-4 md:mt-0">
                    <button
                        disabled={!query}
                        onClick={() => querySearchHandler()}
                        className="btn-primary-small basis-1/3 text-base lg:text-lg mr-4">
                        Search
                    </button>
                    <button
                        disabled={!query}
                        onClick={() => {
                            clearQuerySearch();
                            setQuery(null);
                            setPage(0);
                        }}
                        className="btn-secondary-small basis-1/3 text-base lg:text-lg">
                        Clear
                    </button>
                    {/* <EditQuery query={query} /> */}
                    <DeleteQuery query={query} setQuery={setQuery} />
                </div>
            </div>
            {error && (
                <p className="text-sm font-bold text-red pt-4">
                    {error.data?.statusCode}: {error.data?.message}
                </p>
            )}
        </div>
    );
};

export default Queries;
