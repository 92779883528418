import { useSelector } from 'react-redux';
import { Link, Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';
import { useGetMeQuery, useLogoutMutation } from '../../app/services/auth';
import { useGetSquareProductsQuery } from '../../app/services/orders';
import { LogoutWarning } from '../../components';
import { AuthenticatedModals } from '../../components/Modals';
import { ReactComponent as Tagline } from '../../images/gem-sleep-tagline.svg';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { disableAnalytics } from '../../utils/disableAnalytics';
import * as ChildrenViews from './index';
import useWebWidget from '../../utils/useWebWidget';
import { usePostHog } from 'posthog-js/react';

const Admin = () => {
    disableAnalytics();
    const posthog = usePostHog();
    const location = useLocation();
    const [logout] = useLogoutMutation();
    const { user, isLoggedIn, isPatient } = useSelector((state) => state.auth);
    const { isLoading } = useGetMeQuery(null, { skip: user !== null });
    const { isLoading: catalogLoading, isFetching: catalogFetching } = useGetSquareProductsQuery();

    //Turn off zendesk and posthog
    useWebWidget(false);
    posthog?.stopSessionRecording();

    const activeState = ({ isActive }) => ({
        fontWeight: isActive ? 'bold' : 'normal',
        borderBottom: isActive ? '2px solid #19a756' : '2px solid transparent',
    });

    return (
        <>
            <LogoutWarning />
            {!isLoggedIn ? (
                <Navigate to="/sign-in" state={{ from: location.pathname }} />
            ) : isLoading || catalogLoading || catalogFetching ? (
                <div className="h-screen w-screen bg-purple-dark"></div>
            ) : isPatient ? (
                <Navigate to="/" />
            ) : !user ? (
                <Navigate to="/sign-in" />
            ) : (
                <>
                    <div className="ph-no-capture relative bg-purple-dark min-h-screen text-white lg:flex">
                        <div className="w-full bg-black p-8 lg:w-1/5 lg:max-w-md">
                            <Link
                                to="/admin"
                                className="mb-12 flex scale-75 flex-col items-center text-center lg:scale-100">
                                <Logo className="mx-auto mb-5" />
                                <Tagline className="mx-auto" />
                            </Link>
                            <nav className="mb-10 flex items-center space-x-4 space-y-2 text-xl flex-row flex-wrap lg:flex-col lg:items-start lg:space-x-0 lg:space-y-4 justify-center">
                                <NavLink to="/admin" end={true} style={activeState}>
                                    Patients
                                </NavLink>
                                <NavLink to="administrators" style={activeState}>
                                    Administrators
                                </NavLink>
                                <NavLink to="care-team" style={activeState}>
                                    Care Teams
                                </NavLink>
                                <NavLink to="organizations" style={activeState}>
                                    Organizations
                                </NavLink>
                                <NavLink to="state-services" style={activeState}>
                                    State Services
                                </NavLink>
                                <NavLink to="consults" style={activeState}>
                                    Consults
                                </NavLink>
                                <NavLink to="orders" style={activeState}>
                                    Orders
                                </NavLink>
                                <NavLink to="shop" style={activeState}>
                                    Shop
                                </NavLink>
                                <NavLink to="payers" style={activeState}>
                                    Payers
                                </NavLink>
                                <NavLink to="reports" style={activeState}>
                                    Reports
                                </NavLink>
                            </nav>
                            <button
                                className="btn-secondary w-full"
                                onClick={async (ev) => {
                                    await logout();
                                }}>
                                LOGOUT
                            </button>
                        </div>
                        <div
                            className={`flex-1 overflow-auto bg-purple-dark px-6 sm:px-12 ${
                                navigator.userAgent.indexOf('Win') > -1
                                    ? 'admin-view is-windows'
                                    : 'not-windows'
                            }`}>
                            <Outlet />
                        </div>
                    </div>
                    <AuthenticatedModals />
                </>
            )}
        </>
    );
};

export default Object.assign(Admin, { ...ChildrenViews });
