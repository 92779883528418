import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useGetPayersQuery } from '../../app/services/insurance';
import {
    useCreatePartnerOrganizationMutation,
    useGetPartnerOrganizationTypesQuery,
} from '../../app/services/admin/organizations';
import { CustomReactSelect, CustomInput, CustomCheckbox } from '../../components/InputComponents';
import { convertEnumValueToDisplayString, states } from '../../utils/constants';
import { organizationSchema } from '../../utils/schemas';
import { useNavigate } from 'react-router-dom';

const CreatePartner = () => {
    const navigate = useNavigate();
    const { data: partnerTypes } = useGetPartnerOrganizationTypesQuery();
    const { data: payers } = useGetPayersQuery();
    const [createPartnerOrganization, { error }] = useCreatePartnerOrganizationMutation();

    const partnerTypeOptions =
        partnerTypes?.map((p) => ({
            label: convertEnumValueToDisplayString(p),
            value: p,
        })) || [];

    const submitHandler = async (formData) => {
        const r = await createPartnerOrganization(formData);

        if (r.error) {
            toast.error('Something went wrong...', { theme: 'dark' });
        } else {
            toast.success('Partner added!', { theme: 'dark' });
            navigate('/admin/organizations/list');
        }
    };

    return (
        partnerTypes &&
        payers && (
            <div className="py-12">
                <h2 className="mb-10 font-heading text-2xl lg:text-5xl">Create New Organization</h2>

                {error && (
                    <>
                        <p className="mt-3 font-bold">
                            Error coming from the API (status: {error.status})
                        </p>
                        <p className="font-bold text-red">{error.data?.message}</p>
                    </>
                )}

                <Formik
                    validationSchema={organizationSchema}
                    initialValues={{
                        organizationType: '',
                        name: '',
                        address: '',
                        city: '',
                        state: '',
                        zip: '',
                        latitude: 0,
                        longitude: 0,
                        phone: '',
                        email: '',
                        websiteUrl: '',
                        schedulingLink: '',
                        isReferrer: true,
                    }}
                    onSubmit={submitHandler}>
                    {({ dirty, isValid, isSubmitting }) => (
                        <Form className="space-y-6">
                            <div className="grid grid-cols-2 gap-8">
                                <CustomReactSelect
                                    name="organizationType"
                                    placeholder="Organization Type"
                                    options={partnerTypeOptions}
                                />
                                <CustomInput name="name" label="Name" />
                                <CustomInput name="address" label="Address" />
                                <CustomInput name="city" label="City" />
                                <CustomReactSelect
                                    name="state"
                                    placeholder="State"
                                    options={states}
                                />
                                <CustomInput name="zip" label="Zip" />
                                <CustomInput
                                    type="email"
                                    name="email"
                                    label="Email"
                                    autoComplete="username"
                                />
                                <CustomInput name="phone" label="Phone" />
                                <CustomInput name="websiteUrl" label="Practice Website URL" />
                                <CustomInput name="schedulingLink" label="Scheduling Link" />
                                <CustomReactSelect
                                    name="payerId"
                                    placeholder="Payer"
                                    options={payers?.map((p) => ({ label: p.name, value: p.id }))}
                                />
                                <CustomCheckbox name="isReferrer" label="Is Referrer" />
                            </div>
                            <button
                                className="btn-primary w-full"
                                type="submit"
                                disabled={!dirty || !isValid || isSubmitting}>
                                Create
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    );
};

export default CreatePartner;
