import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
    useConnectToOrganizationMutation,
    useDisconnectFromOrganizationMutation,
} from '../../../../app/services/admin/organizations';
import { useGetPartnerOrganizationsQuery } from '../../../../app/services/insurance';

const PatientReferralAttribution = ({ userId, patientProfile }) => {
    const {
        data: organizations,
        isLoading,
        isFetching,
    } = useGetPartnerOrganizationsQuery({ paginated: false });
    const [connectToOrganization] = useConnectToOrganizationMutation();
    const [disconnectFromOrganization] = useDisconnectFromOrganizationMutation();

    const submitHandler = async (data) => {
        const results = await Promise.all([
            data.referToOrganizationId !== ''
                ? // if a organization id is selected, assign.
                  connectToOrganization({
                      userId,
                      referTo: patientProfile.id,
                      orgId: +data.referToOrganizationId,
                  })
                : // otherwise, if the user has a current referToOrganizationId, unassign by using the practice ID in the patient profile or do nothing
                  patientProfile.referToOrganizationId
                  ? disconnectFromOrganization({
                        userId,
                        referTo: patientProfile.id,
                        orgId: patientProfile.referToOrganizationId,
                    })
                  : undefined,

            data.referFromOrganizationId !== ''
                ? connectToOrganization({
                      userId,
                      referFrom: patientProfile.id,
                      orgId: +data.referFromOrganizationId,
                  })
                : patientProfile.referFromOrganizationId
                  ? disconnectFromOrganization({
                        userId,
                        referFrom: patientProfile.id,
                        orgId: patientProfile.referFromOrganizationId,
                    })
                  : undefined,
        ]);

        if (results.some((r) => r?.error)) {
            toast.error('Something went wrong', { theme: 'dark' });
        } else {
            toast.success('Referral Updated', { theme: 'dark' });
        }
    };

    return (
        <div className="rounded-xl bg-gray-darker p-5">
            <p className="mb-3 text-xl font-bold">Patient Referral Attribution:</p>
            {(isLoading || isFetching) && <p>Loading partners...</p>}
            {organizations && (
                <>
                    <Formik
                        initialValues={{
                            referToOrganizationId: patientProfile.referToOrganizationId ?? '',
                            referFromOrganizationId: patientProfile.referFromOrganizationId ?? '',
                        }}
                        enableReinitialize
                        onSubmit={submitHandler}>
                        {({ dirty }) => (
                            <Form className="space-y-4">
                                <div className="mb-4">
                                    <p className="text-gray">Referred From: </p>
                                    <Field
                                        name="referFromOrganizationId"
                                        as="select"
                                        className="w-full text-black max-w-[500px]">
                                        <option value={''}>N/A</option>
                                        {organizations.results?.map((partner, idx) => (
                                            <option
                                                key={`partner-${partner.id}`}
                                                value={partner.id}>
                                                {partner.name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>

                                <div className="space-y-2">
                                    <p className="text-gray">Referred To: </p>
                                    <Field
                                        name="referToOrganizationId"
                                        as="select"
                                        className="w-full text-black max-w-[500px]">
                                        <option value={''}>N/A</option>
                                        {organizations.results?.map((partner, idx) => (
                                            <option
                                                key={`partner-${partner.id}`}
                                                value={partner.id}>
                                                {partner.name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>

                                <button className="btn-primary-small" disabled={!dirty}>
                                    Save
                                </button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};

export default PatientReferralAttribution;
