import {
    formatDate,
    formatPhoneNumber,
    formatOralQuestionnaireResponse,
    formatStopBangResponses,
    formatAltTreatmentResponses,
    getAgeFromDateString,
    formatBirthdate,
} from '../../../../utils/constants';
import { Assessment } from '../../../../utils/assessment';

const PatientInfo = ({ patient }) => {
    return (
        patient && (
            <div className="grid space-y-2 md:grid-cols-3 md:space-x-4 md:space-y-0">
                <div className="flex-1 text-sm md:text-base">
                    <h3 className="mb-5 text-base font-bold lg:text-xl">Patient information:</h3>
                    <ul>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Age: </span>{' '}
                            {getAgeFromDateString(patient.birthdate)}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Join date: </span>{' '}
                            {formatDate(patient.createdAt)}
                        </li>
                        {patient.unverifiedEmail ? (
                            <li className="py-1 h-8 items-center">
                                <div
                                    className={`w-min max-w-full daisy-tooltip daisy-tooltip-right text-left`}
                                    data-tip={patient.unverifiedEmail}>
                                    <p className="tracking-wide whitespace-nowrap overflow-hidden text-ellipsis">
                                        <span className="text-gray-light ">Unverified Email: </span>{' '}
                                        {patient.unverifiedEmail}
                                    </p>
                                </div>
                            </li>
                        ) : (
                            <li className="py-1 h-8 items-center">
                                <div
                                    className={`w-min max-w-full daisy-tooltip daisy-tooltip-right text-left`}
                                    data-tip={patient.email}>
                                    <p className="tracking-wide whitespace-nowrap overflow-hidden text-ellipsis">
                                        <span className="text-gray-light ">Email: </span>{' '}
                                        {patient.email}
                                    </p>
                                </div>
                            </li>
                        )}
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Address 1: </span> {patient.street1}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Address 2: </span> {patient.street2}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">City: </span> {patient.city}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">State: </span> {patient.state}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Zip: </span> {patient.zip}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Phone Number: </span>{' '}
                            {formatPhoneNumber(patient.phone)}
                        </li>
                        <li className="py-1 tracking-wide">
                            <span className="text-gray-light">Birthdate: </span>{' '}
                            {formatBirthdate(patient.birthdate)}
                        </li>
                    </ul>
                </div>

                {patient.oralQuestionnaireResponses && (
                    <div className="flex-1 text-sm md:text-base">
                        <h3 className="text-base font-bold lg:text-xl">
                            Oral questionnaire results:
                        </h3>
                        <ul>
                            {Object.keys(patient.oralQuestionnaireResponses).map((k, idx) =>
                                k !== 'id' ? (
                                    <li className="py-1" key={`oq-${k}`}>
                                        {formatOralQuestionnaireResponse(
                                            k,
                                            patient.oralQuestionnaireResponses[k],
                                        )}
                                    </li>
                                ) : null,
                            )}
                        </ul>
                    </div>
                )}

                {patient.stopBangResponses && (
                    <>
                        <div className="flex-1 text-sm md:text-base">
                            <h3 className="mb-5 text-base font-bold lg:text-xl">
                                Stop Bang results:
                            </h3>
                            <ul>
                                <li>
                                    <span className="text-gray-light">Score: </span>{' '}
                                    {Assessment.GetAssessmentScore(patient)}
                                </li>
                                {formatStopBangResponses(
                                    patient.stopBangResponses,
                                    patient.birthdate,
                                )}
                            </ul>
                        </div>
                        <div className="flex-1 text-sm md:text-base">
                            <h3 className="mb-5 text-base font-bold lg:text-xl">
                                Alt treatment questionnaire:
                            </h3>
                            <ul>{formatAltTreatmentResponses(patient.stopBangResponses)}</ul>
                        </div>
                    </>
                )}
            </div>
        )
    );
};

export default PatientInfo;
