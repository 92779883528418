import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetOrganizationQuery, useGetPayersQuery } from '../../app/services/insurance';
import {
    useGetPartnerOrganizationTypesQuery,
    useUpdatePartnerOrganizationMutation,
} from '../../app/services/admin/organizations';
import { CustomCheckbox, CustomInput, CustomReactSelect } from '../../components/InputComponents';
import { states } from '../../utils/constants';
import { organizationSchema } from '../../utils/schemas';

const UpdatePartner = () => {
    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { data: Partner, isLoading: partnerLoading } = useGetOrganizationQuery(partnerId);
    const { data: partnerTypes, isLoading: partnerTypesLoading } =
        useGetPartnerOrganizationTypesQuery();
    const { data: payers, isLoading: payersLoading } = useGetPayersQuery();
    const [updatePartnerOrganization] = useUpdatePartnerOrganizationMutation();

    const partnerTypeOptions =
        partnerTypes?.map((p) => ({
            label: p,
            value: p,
        })) || [];

    const submitHandler = async (formData) => {
        if (formData.payerId === '') {
            delete formData.payerId;
        }
        await updatePartnerOrganization({ id: +partnerId, ...formData })
            .unwrap()
            .then((res) => {
                toast.success('Organization Updated!', { theme: 'dark' });
                navigate('/admin/organizations/list');
            })
            .catch((error) => {
                toast.error(error.data?.message, { theme: 'dark' });
            });
    };

    return partnerLoading || partnerTypesLoading || payersLoading ? (
        <div className="py-12">
            <h2 className="mb-10 font-heading text-2xl lg:text-5xl">Loading...</h2>
        </div>
    ) : (
        <div className="py-12">
            <h2 className="mb-10 font-heading text-2xl lg:text-5xl">Update {Partner?.name}</h2>
            <Formik
                validationSchema={organizationSchema}
                initialValues={{
                    organizationType: Partner.organizationType ?? '',
                    name: Partner.name ?? '',
                    address: Partner.address ?? '',
                    city: Partner.city ?? '',
                    state: Partner.state ?? '',
                    zip: Partner.zip ?? '',
                    phone: Partner.phone.replace(/[- )(]/g, '') ?? '',
                    email: Partner.email ?? '',
                    websiteUrl: Partner.websiteUrl ?? '',
                    schedulingLink: Partner.schedulingLink ?? '',
                    payerId: Partner.payerId ?? '',
                    isReferrer: Partner.isReferrer ?? true,
                }}
                onSubmit={submitHandler}>
                {({ dirty, isValid, isSubmitting }) => (
                    <Form className="space-y-6">
                        <div className="grid grid-cols-2 gap-8">
                            <CustomReactSelect
                                name="organizationType"
                                placeholder="Partner Type"
                                options={partnerTypeOptions}
                            />
                            <CustomInput name="name" label="Name" />
                            <CustomInput name="address" label="Address" />
                            <CustomInput name="city" label="City" />
                            <CustomReactSelect name="state" placeholder="State" options={states} />
                            <CustomInput name="zip" label="Zip" />
                            <CustomInput
                                type="email"
                                name="email"
                                label="Email"
                                autoComplete="username"
                            />
                            <CustomInput name="phone" label="Phone" />
                            <CustomInput name="websiteUrl" label="Practice Website URL" />
                            <CustomInput name="schedulingLink" label="Scheduling Link" />
                            <CustomReactSelect
                                name="payerId"
                                placeholder="Payer"
                                options={payers?.map((p) => ({ label: p.name, value: p.id }))}
                            />
                            <CustomCheckbox name="isReferrer" label="Is Referrer" />
                        </div>
                        <button
                            className="btn-primary w-full"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}>
                            Update practice
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdatePartner;
