import { useQuery } from '@apollo/client';
import { addMonths, differenceInCalendarMonths, differenceInDays, set } from 'date-fns';
import { useEffect, useState } from 'react';
import AvailableSlots from './_AvailableSlots';
import DayPicker from './_DayPicker';
import { AVAILABLE_SLOTS_FOR_RANGE, DAYS_AVAILABLE_FOR_RANGE } from './gqlQueries';
import { MAX_DAYS_OUT_SCHEDULING } from '../../../utils/constants';

const NoAvailability = () => (
    <div className="flex flex-col items-center bg-white px-5 pt-4 text-gray-darker">
        <p className="text-2xl font-bold font-heading">No Availability</p>
        <span className="mt-4 mb-2">
            Sorry, we do not have any available appointments at this time.
        </span>
        <span className="mt-2 mb-8">
            You can either check back another day, or call us at <b>(833) 946-4436.</b>
        </span>
    </div>
);

const Pickers = ({ provider_id, provider_ids, selectedAppointmentType, onAppointmentBooked }) => {
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [dayFromMonth, setDayFromMonth] = useState(new Date());
    const [ready, setReady] = useState(false);
    //Counts how many days have been checked for availability.
    //If it reaches max, then we have exceeded the days we schedule out
    const [maxDaysOut, setMaxDaysOut] = useState(false);

    const { data: daysAvailableData, loading: daysLoading } = useQuery(DAYS_AVAILABLE_FOR_RANGE, {
        variables: {
            org_level: true,
            date_from_month: dayFromMonth,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
            appt_type_id: selectedAppointmentType.id,
            provider_id: provider_id,
            provider_ids: provider_ids,
        },
    });

    const {
        data: availableSlotsData,
        refetch: refetchSlots,
        loading: slotsLoading,
    } = useQuery(AVAILABLE_SLOTS_FOR_RANGE, {
        variables: {
            org_level: true,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
            appt_type_id: selectedAppointmentType.id,
            provider_id: provider_id,
            provider_ids: provider_ids,
            end_date: selectedDay.toString(),
            start_date: selectedDay.toString(),
        },
        skip: !ready || !daysAvailableData?.daysAvailableForRange?.length > 0,
    });

    //Moves through days looking for available timeslots, skipping over querying weekends
    useEffect(() => {
        const daysAvailable = daysAvailableData?.daysAvailableForRange;
        if (!ready && daysAvailable) {
            if (daysAvailable.length > 0) {
                setReady(true);
            } else {
                // set nextAvailableDay to first day of next month
                let nextAvailableDay = set(addMonths(dayFromMonth, 1), { date: 1 });
                // look at next month
                setSelectedDay(nextAvailableDay);
                setDayFromMonth(nextAvailableDay);
                if (differenceInDays(nextAvailableDay, new Date()) > MAX_DAYS_OUT_SCHEDULING) {
                    setMaxDaysOut(true);
                    setReady(true);
                }
            }
        }
    }, [daysAvailableData, availableSlotsData, maxDaysOut, ready, selectedDay, dayFromMonth]);

    //Changes month if selected day is not in same month as was previously set
    useEffect(() => {
        if (differenceInCalendarMonths(selectedDay, dayFromMonth) !== 0) {
            setDayFromMonth(selectedDay);
        }
    }, [selectedDay, dayFromMonth]);

    if (!ready) {
        return <p className="text-center py-6 font-heading text-5xl">Loading...</p>;
    } else if (ready && maxDaysOut) {
        return <NoAvailability />;
    } else {
        return (
            <div className="flex flex-col items-center bg-white px-5 pt-6 text-gray-darker">
                <p className="text-2xl font-bold">Select Date and Time</p>
                <div className="w-full">
                    <div className="mx-auto">
                        <DayPicker
                            daysAvailableForRange={daysAvailableData?.daysAvailableForRange}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                        />
                    </div>
                    <div className="mx-auto">
                        <AvailableSlots
                            data={availableSlotsData}
                            refetch={refetchSlots}
                            onAppointmentBooked={
                                onAppointmentBooked
                                    ? (appointment) => {
                                          return onAppointmentBooked(appointment);
                                      }
                                    : undefined
                            }
                            selectedDay={selectedDay}
                            slotsLoading={slotsLoading}
                            daysLoading={daysLoading}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default Pickers;
