import { api } from './auth';

function providesPartialList(resultsWithIds, tagType) {
    return resultsWithIds?.results
        ? [
              { type: tagType, id: 'PARTIAL_LIST' },
              ...resultsWithIds.results.map(({ id }) => ({ type: tagType, id })),
          ]
        : [{ type: tagType, id: 'PARTIAL_LIST' }];
}

const insuranceAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        createConsent: builder.mutation({
            query: (consentData) => ({
                url: `/consents`,
                method: 'POST',
                body: { documents: consentData },
            }),
        }),
        createNikoOrder: builder.mutation({
            query: (orderData) => ({
                url: `/shop/orders`,
                method: 'POST',
                body: orderData,
            }),
            invalidatesTags: ['Orders'],
        }),
        createPatientInsurance: builder.mutation({
            query: ({ userId, data }) => ({
                url: `/users/${userId}/insurance`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Insurance'],
        }),
        getMyInsurance: builder.query({
            query: (userId) => ({
                url: `/users/${userId}/insurance?inactive=true`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => {
                const activeInsurance = response.find((insurance) =>
                    ['Active', 'Review', 'Failed'].includes(insurance.insuranceStatus),
                );
                return activeInsurance;
            },
            providesTags: (result, arg) => (result ? [{ type: 'Insurance', id: arg?.userId }] : []),
        }),
        getOrganization: builder.query({
            query: (id) => `/organization/${id}`,
            providesTags: (result, error, arg) => [{ type: 'Organization', id: result.id }],
        }),
        getPartnerOrganizations: builder.query({
            query: ({ paginated, skip, take }) => {
                if (paginated)
                    return {
                        url: `/organization?skip=${skip}&take=${take}`,
                        method: 'GET',
                    };
                else
                    return {
                        url: `/organization?`,
                        method: 'GET',
                    };
            },
            providesTags: (result) => providesPartialList(result, 'Organization'),
        }),
        getPatientConsents: builder.query({
            query: () => ({
                url: `/consents`,
            }),
        }),
        getPayers: builder.query({
            query: () => ({
                url: `/payers`,
                method: 'GET',
            }),
            providesTags: ['Payers'],
        }),
        getStateServices: builder.query({
            query: (state) => ({
                url: `/service/${state}`,
                method: 'GET',
            }),
        }),
        updatePatientInsurance: builder.mutation({
            query: ({ userId, insuranceId, data }) => ({
                url: `/users/${userId}/insurance/${insuranceId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Insurance', 'OrderInfo', 'OrderHistory', 'PatientOrderHistory'],
        }),
        createAppointmentInvoice: builder.mutation({
            query: (appointmentId) => ({
                url: `/niko/create-invoice`,
                method: 'POST',
                body: { appointmentId },
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateConsentMutation,
    useCreateNikoOrderMutation,
    useCreatePatientInsuranceMutation,
    useGetMyInsuranceQuery,
    useGetOrganizationQuery,
    useGetPartnerOrganizationsQuery,
    useGetPatientConsentsQuery,
    useGetPayersQuery,
    useGetStateServicesQuery,
    useLazyGetMyInsuranceQuery,
    useUpdatePatientInsuranceMutation,
    useCreateAppointmentInvoiceMutation,
} = insuranceAPI;
