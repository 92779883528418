import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FaCheck } from 'react-icons/fa';
import { useUpdateStateServiceMutation } from '../../app/services/admin/insurance';
import { useGetAllStateServicesQuery } from '../../app/services/auth';
import { Modal } from '../../components';
import {states} from '../../utils/constants'

const StateServices = () => {
    const { data, isLoading, refetch } = useGetAllStateServicesQuery();
    const [updateStateService] = useUpdateStateServiceMutation();
    const [stateServices, setStateServices] = useState(null);
    const [editingStateServices, setEditingStateServices] = useState(null);
    useEffect(() => {
        if (isLoading) return;
        const tempStateServices = [];
        for (const item of data) {
            tempStateServices.push({
                state: item.state,
                available: item.available,
                telehealthForState: item.telehealthForState,
                modified: false,
            });
        }
        setStateServices(tempStateServices);
    }, [isLoading, data, setStateServices]);

    const toggleAvailable = (stateService) => {
        stateService.available = !stateService.available;
        stateService.modified = true;
        setStateServices(
            stateServices.map((item) => {
                return stateService.state === item.state ? stateService : item;
            }),
        );
    };

    const toggleTelehealth = (stateService) => {
        stateService.telehealthForState = !stateService.telehealthForState;
        stateService.modified = true;
        setStateServices(
            stateServices.map((item) => {
                return stateService.state === item.state ? stateService : item;
            }),
        );
    };

    const saveStateService = async (stateService) => {
        await updateStateService(stateService);
        stateService.modified = false;
        setStateServices(
            stateServices.map((item) => {
                return stateService.state === item.state ? stateService : item;
            }),
        );
        refetch();
        setEditingStateServices(null);

    };

    const handleUpdateSubmit = (values) =>  {
        const updatedService = stateServices.find(
            (service) => service.state === values.state
        );
        Object.assign(updatedService, values);
        saveStateService(updatedService);
    };

    return isLoading ? (
        <p className="py-6 font-heading text-5xl">Loading...</p>
    ) : (
        <>
            <div className="py-6">
                <h2 className="mb-5 flex items-center justify-between">
                    <span className="font-heading text-5xl">State Services</span>
                    <button
                        className="border-b-2 border-gem-green font-bold"
                        disabled={false} 
                        onClick={(event) => {
                            setEditingStateServices(true)
                        }}>
                        Edit State Services
                    </button>
                </h2>
                <table className="table-fixed">
                    <thead>
                        <tr className="border-b">
                            <th className="w-1/6 p-2 text-left">State</th>
                            <th className="w-1/4 p-2 text-left">Available</th>
                            <th className="w-1/4 p-2 text-left">Telehealth Required</th>
                            <th className="w-1/4 p-2 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-dark text-sm">
                        {stateServices &&
                            stateServices.length > 0 &&
                            stateServices.map((stateService) => (
                                <tr key={`${stateService.state}`}>
                                    <td className="p-2">{stateService.state}</td>
                                    <td className="p-2">
                                        <div className="flex items-center space-x-2">
                                            <div className="relative">
                                                <input
                                                    type="checkbox"
                                                    name="Available"
                                                    value={stateService.available}
                                                    checked={stateService.available}
                                                    onChange={(event) => toggleAvailable(stateService)}
                                                    className="float-left h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-green-600 checked:bg-green-600 focus:outline-none"
                                                />
                                                <FaCheck className="absolute-center pointer-events-none w-2 scale-150 text-white opacity-0 transition" />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        <div className="flex items-center space-x-2">
                                            <div className="relative">
                                                <input
                                                    type="checkbox"
                                                    name="Telehealth"
                                                    value={stateService.telehealthForState}
                                                    checked={stateService.telehealthForState}
                                                    onChange={(event) => toggleTelehealth(stateService)}
                                                    className="float-left h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-green-600 checked:bg-green-600 focus:outline-none"
                                                />
                                                <FaCheck className="absolute-center pointer-events-none w-2 scale-150 text-white opacity-0 transition" />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                    <div className="flex items-center space-x-2">
                                        <div className="relative">
                                            <button
                                                className="btn-primary-small"
                                                disabled={!stateService.modified}
                                                onClick={(event) => saveStateService(stateService)}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                </tr>
                            ))}
                        {stateServices && stateServices.length === 0 && (
                            <tr>
                                <td className="p-2">No data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {stateServices && editingStateServices !== null && (
            <Modal
            showClose={true}
            isOpen={!!editingStateServices}
            closeHandler={() => {
                setEditingStateServices(null);
            }}>
                <div className="Edit State Services Modal">
                    <section>
                        <h2  className="font-heading text-3xl">
                            Edit State Services
                        </h2>
                        <Formik
                           initialValues={{
                            state: stateServices[0]?.state || '',
                            available: stateServices[0]?.available || false,
                            telehealthForState: stateServices[0]?.telehealthForState || false,
                        }}
                            onSubmit={handleUpdateSubmit}>
                            {({ values, handleChange, setValues }) => (
                               <Form>
                                    <div>
                                        <label>Select State</label>
                                        <Field
                                        component="select"
                                        multiple={false}
                                        className="w-full p-2 border border-gray-300 rounded-md bg-white appearance-none text-base leading-6 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        onChange={(e) => {
                                            const selectedService = stateServices.find(
                                                (service) => service.state === e.target.value
                                            );
                                            setValues({
                                                state: selectedService.state,
                                                available: selectedService.available,
                                                telehealthForState: selectedService.telehealthForState,
                                            });
                                        }}
                                        name="state"
                                        value={values.state}>
                                        {states.map((state, index) => (
                                            <option key={index} value={state.value}>
                                                {state.label}
                                            </option>
                                        ))}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        <div className='p-4 border-2 rounded-md'>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Available</label>
                                            <div className="relative flex items-center h-6 w-6">
                                                <Field
                                                type="checkbox"
                                                name="available"
                                                checked={values.available}
                                                onChange={handleChange}
                                                className="h-6 w-6 cursor-pointer appearance-none border-4 border-green-600 bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none absolute"
                                                />
                                                <FaCheck className="absolute inset-0 m-auto w-3 h-3 text-white pointer-events-none" />
                                            </div>
                                        </div>
                                        <div className='p-4 border-2 rounded-md'>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Telehealth</label>
                                            <div className="relative flex items-center h-6 w-6">
                                                <Field
                                                type="checkbox"
                                                name="telehealthForState"
                                                checked={values.telehealthForState}
                                                onChange={handleChange}
                                                className="h-6 w-6 cursor-pointer appearance-none border-4 border-green-600 bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none absolute"
                                                />
                                                    <FaCheck className="absolute inset-0 m-auto w-3 h-3 text-white pointer-events-none" />
                                            </div>
                                        </div>
                                    </div>        
                                    <footer>
                                        <button className="mt-10 btn-primary w-full" type="submit">
                                            Save Changes
                                        </button>
                                    </footer>
                                </Form> 
                                )}
                        </Formik>
                    </section>
                </div>
            </Modal>
            )}
        </>
    );
};

export default StateServices;
