import topRightArrow from '../../images/top-right-arrow.svg';

const QuoteCard = ({ quoteData, blogPost, link, linkText, externalPost, className }) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)');
    const url = urlPattern.test(link) ? link : `https://mygemsleep.com/post/${link}`;

    return !quoteData ? null : (
        <div className={`group relative overflow-hidden bg-purple/25 p-5 lg:p-8 ${className}`}>
            {quoteData?.image && (
                <img
                    src={quoteData.image.url}
                    alt={quoteData.image.alt}
                    className="absolute-center z-0 object-cover object-center opacity-25 h-full w-full"
                />
            )}
            <blockquote className="relative py-9 lg:py-12">
                <p className="text-lg lg:text-2xl">{quoteData?.quote}</p>
                <p className="text-right text-sm font-semibold">
                    {quoteData['quote-title'] ? quoteData['quote-title'] : quoteData?.name}
                </p>
            </blockquote>
            {externalPost && (
                <a
                    href={externalPost.url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn group relative w-full bg-white p-4 text-left transition duration-200 ease-out-quad group-hover:drop-shadow-xl lg:p-8">
                    <span className="absolute right-4 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full bg-green-400 text-white transition duration-200 ease-out-quad group-hover:scale-105 lg:right-8 lg:h-12 lg:w-12">
                        <p className="w-5/6">{externalPost.name}</p>
                        <img
                            src={topRightArrow}
                            alt=""
                            className="absolute-center w-2 transition lg:w-3"
                        />
                    </span>
                </a>
            )}
            {!externalPost && link && linkText && (
                <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn group relative w-full bg-white p-4 text-left transition duration-200 ease-out-quad group-hover:drop-shadow-xl lg:p-8">
                    <p className="w-5/6">{linkText}</p>
                    <span className="absolute right-4 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full bg-green-400 text-white transition duration-200 ease-out-quad group-hover:scale-105 lg:right-8 lg:h-12 lg:w-12">
                        <img
                            src={topRightArrow}
                            alt=""
                            className="absolute-center w-2 transition lg:w-3"
                        />
                    </span>
                </a>
            )}
        </div>
    );
};

export default QuoteCard;
