import { useSelector } from 'react-redux';
import { NeedANewSleepStudy } from '../../components/SleepStudy';
import { useNavigate } from 'react-router-dom';

function SleepStudyInReview() {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    const prescriptionUploaded = user?.prescriptionFileId;

    if (!prescriptionUploaded) {
        return (
            <div className="layout-outlet-root max-w-3xl space-y-4">
                <h2 className="font-heading text-[44px] lg:text-7xl flex items-center">
                    <span>You're almost done!</span>
                </h2>
                <p className="leading-normal lg:text-xl">
                    You have successfully uploaded your Sleep Study.
                </p>
                <p className="leading-normal lg:text-xl">
                    You have <span className="font-bold">not</span> uploaded your{' '}
                    <span className="font-bold">prescription</span>. Please upload a copy of your
                    prescription so we can determine your eligibility.
                </p>
                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        navigate('../study-upload');
                    }}>
                    Upload Prescription
                </button>
                <NeedANewSleepStudy />
            </div>
        );
    } else {
        return (
            <div className="layout-outlet-root max-w-3xl">
                <h2 className="font-heading text-[44px] lg:text-7xl mb-6 flex items-center">
                    <span>Your Sleep Study is in Good Hands!</span>
                </h2>
                <p className="mb-4 leading-normal lg:text-xl">
                    You have successfully uploaded your Sleep Study and Prescription.
                </p>
                <p className="mb-4 leading-normal lg:text-xl">
                    Our GEM SLEEP Providers will complete their review within 5-7 business days.
                    Once the review is complete you will receive a notification to review your
                    personalized treatment recommendation.
                </p>
                <p className="mb-4 leading-normal lg:text-xl">
                    If more information is needed, we will contact you directly via the phone number
                    provided.
                </p>
                <p className="mb-8 leading-normal lg:text-xl font-bold">Sleep well!</p>
            </div>
        );
    }
}

export default SleepStudyInReview;
