import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppointmentBooking, ConsultationConfirmation } from '../Appointments';
import { GemSleepPath } from '../Diagnosis';

import { useCheckoutContext } from './CheckoutContext';
import { setReschedulingFlow } from '../../features/ui/uiSlice';

const RequiredConsultBooking = ({ requiredByState, onAppointmentBooked, onBookingComplete }) => {
    const dispatch = useDispatch();
    const [screen, setScreen] = useState('interstitial'); //'interstitial', 'booking', 'confirmation'

    const { skipInsurance } = useCheckoutContext();
    const { hstInCart } = useSelector((state) => state.cart);

    return (
        <>
            {screen === 'interstitial' && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="layout-outlet-root space-y-5 max-w-2xl">
                        <h5 className="font-heading text-4xl font-bold lg:text-5xl">
                            Next Up: Schedule a quick virtual visit.
                        </h5>
                        <p className="text-sm lg:text-base">
                            {hstInCart
                                ? 'A virtual visit is needed before we can ship your home sleep study. During your visit, our providers will review your medical history and confirm the next steps.'
                                : 'You are required to schedule a virtual visit before you can receive your purchase from GEM. During your visit, our providers will review your medical history and confirm the next steps.'}
                        </p>
                        <button
                            className="btn-primary w-full"
                            onClick={() => {
                                setScreen('booking');
                                dispatch(setReschedulingFlow(false));
                            }}>
                            SCHEDULE VIRTUAL VISIT
                        </button>
                        {!requiredByState && (
                            <>
                                <hr className="my-4" />
                                <button
                                    className="btn-shell w-full"
                                    onClick={() => skipInsurance({ showAlert: true })}>
                                    continue without insurance
                                </button>
                                <h6 className="mb-5 text-center font-heading text-gem-green text-xl font-bold lg:text-2xl">
                                    No Virtual Visit Required
                                </h6>
                            </>
                        )}
                    </div>
                </motion.div>
            )}
            {screen === 'booking' && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <AppointmentBooking
                        onAppointmentBooked={() => {
                            if (onAppointmentBooked) {
                                onAppointmentBooked();
                            }
                            setScreen('confirmation');
                        }}
                    />
                </motion.div>
            )}
            {screen === 'confirmation' && (
                <>
                    <div className="layout-outlet-root max-w-2xl">
                        <ConsultationConfirmation onBookingComplete={onBookingComplete} />
                    </div>
                    {hstInCart && (
                        <GemSleepPath
                            descriptionText="Once you complete your virtual visit we will ship your Home Sleep Study."
                            pathLabels={[
                                { text: 'Sleep apnea assessment', centered: true },
                                { text: 'Virtual Visit', centered: true },
                                { text: 'Sleep Study', centered: true },
                                { text: 'The right treatment', centered: true },
                                { text: 'Personalized support', centered: true },
                            ]}
                            stepsCompleted={1}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default RequiredConsultBooking;
