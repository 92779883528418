import { addDays, subYears } from 'date-fns';
import { api } from '../auth';
import {
    determineSearchTermValue,
    handleNullOptions,
    orderByObjectKeysForOrders,
} from '../../../utils/constants';

const adminOrdersAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        addOrderItem: builder.mutation({
            query: ({ orderId, userId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/items/`,
                method: 'POST',
                body: { ...values },
            }),
            invalidatesTags: (arg) => [
                { type: 'OrderHistory', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
                'Cart',
            ],
        }),
        addShippingDetails: builder.mutation({
            query: ({ userId, orderId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/shipping`,
                method: 'POST',
                body: { ...values },
            }),
            invalidatesTags: ['LocalOrderHistory'],
        }),
        createOrder: builder.mutation({
            query: ({ userId }) => ({
                url: `/admin/user/${userId}/orders`,
                method: 'POST',
            }),
            invalidatesTags: ['OrderHistory', 'PatientOrderHistory'],
        }),
        getPatientCart: builder.query({
            query: (userId) => `/purchases/cart?userId=${userId}`,
            providesTags: (result) => ['Cart'],
        }),
        getOrderInfo: builder.query({
            query: ({ userId, orderId }) => `/admin/user/${userId}/orders/${orderId}`,
            providesTags: (result) => [{ type: 'OrderInfo', id: result.id }],
        }),
        getPaginatedOrderHistory: builder.query({
            query: ({
                appointmentStatus,
                appointmentType,
                careTeam,
                cpapOrderStatus,
                estimateStatus,
                fulfillmentStatus,
                homeSleepStudyStatus,
                mdStatus,
                initialCompliance,
                insurance,
                insuranceStatus,
                lastCompliance,
                startDate,
                endDate = null,
                orderDesc,
                orderByKey,
                orderItemType,
                orderStatus,
                orderType,
                priorAuthStatus,
                referFromOrganization,
                skip,
                searchTermType,
                searchTermValue,
                supplyOrdersStatus,
            }) => {
                const searchTermWhereObject = determineSearchTermValue(
                    searchTermValue,
                    searchTermType,
                );

                let whereObject = {
                    ...(startDate && {
                        orderDate: {
                            gte: new Date(startDate), // Day selected
                            lte: endDate
                                ? addDays(new Date(endDate), 1)
                                : addDays(new Date(startDate), 1), // Next day selected if no end date specified
                        },
                    }),
                    ...(estimateStatus && handleNullOptions({ estimateStatus: estimateStatus })),
                    ...(fulfillmentStatus && { fulfillmentStatus }),
                    ...((insurance || insuranceStatus) && {
                        insurance: {
                            ...(insurance && { payerId: insurance }),
                            ...(insuranceStatus && {
                                insuranceStatus: insuranceStatus,
                            }),
                        },
                    }),
                    ...(orderStatus && { orderStatus }),
                    ...(orderType && { orderType }),
                    ...(priorAuthStatus && handleNullOptions({ priorAuthStatus: priorAuthStatus })),

                    //Search the appointment status
                    ...((appointmentStatus || appointmentType) && {
                        appointments: {
                            some: {
                                ...(appointmentStatus && {
                                    appointmentStatus: appointmentStatus,
                                }),
                                ...(appointmentType && { appointmentType: appointmentType }),
                            },
                        },
                    }),

                    //Search for order items by Category ID or Sku (skus as an array)
                    ...(orderItemType && {
                        orderItems: { some: { itemCategory: orderItemType.value } },
                    }),

                    //Search values which are on the user object or connected tables
                    ...((careTeam ||
                        cpapOrderStatus ||
                        homeSleepStudyStatus ||
                        initialCompliance ||
                        lastCompliance ||
                        mdStatus ||
                        referFromOrganization ||
                        searchTermValue ||
                        supplyOrdersStatus) && {
                        user: {
                            ...(careTeam && { careTeam: { some: { careTeamUserId: careTeam } } }),
                            //Search for last compliance
                            ...(lastCompliance && {
                                appointments: {
                                    ...(lastCompliance === 'none' && {
                                        none: {
                                            appointmentType: 'Compliance Visit',
                                            appointmentStatus: 'OCCURRED',
                                        },
                                    }),
                                    ...(lastCompliance !== 'none' && {
                                        some: {
                                            startAt: {
                                                ...(lastCompliance === 'gte' && {
                                                    gte: subYears(new Date(), 1),
                                                }), // Date one year in the past
                                                ...(lastCompliance === 'lte' && {
                                                    lte: subYears(new Date(), 1),
                                                }), // Date one year in the past
                                            },
                                            appointmentType: 'Compliance Visit',
                                            appointmentStatus: 'OCCURRED',
                                        },
                                        ...(lastCompliance === 'lte' && {
                                            none: {
                                                startAt: {
                                                    gte: subYears(new Date(), 1),
                                                },
                                                appointmentType: 'Compliance Visit',
                                                appointmentStatus: 'OCCURRED',
                                            },
                                        }),
                                    }),
                                },
                            }),

                            //Patient profile values
                            ...((mdStatus ||
                                cpapOrderStatus ||
                                homeSleepStudyStatus ||
                                initialCompliance ||
                                referFromOrganization ||
                                supplyOrdersStatus) && {
                                patientProfile: {
                                    ...(cpapOrderStatus && { cpapOrderStatus: cpapOrderStatus }),
                                    ...(homeSleepStudyStatus && {
                                        homeSleepStudyStatus: homeSleepStudyStatus,
                                    }),
                                    ...(mdStatus && { mdStatus: mdStatus }),
                                    ...(referFromOrganization && {
                                        referFromOrganizationId: referFromOrganization.id,
                                    }),
                                    ...(supplyOrdersStatus && {
                                        supplyOrdersStatus: supplyOrdersStatus,
                                    }),
                                    ...(initialCompliance && {
                                        treatments: {
                                            some: {
                                                initialCompliance: initialCompliance,
                                                isActive: true,
                                            },
                                        },
                                    }),
                                },
                            }),
                            ...(searchTermValue && searchTermWhereObject),
                        },
                    }),
                };

                const orderByObject = orderByObjectKeysForOrders.get(orderByKey);

                orderByObject[Object.keys(orderByObject)[0]] = orderDesc ? 'desc' : 'asc';

                return {
                    url: `/admin/orders`,
                    method: 'POST',
                    body: {
                        skip: skip.toString(),
                        take: '10',
                        where: whereObject ? JSON.stringify({ ...whereObject }) : null,
                        orderBy: JSON.stringify(orderByObject),
                    },
                };
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.results.map(({ id }) => ({ type: 'OrderHistory', id })),
                          'OrderHistory',
                      ]
                    : ['OrderHistory'],
        }),
        getPatientOrderHistory: builder.query({
            query: (userId) => `/admin/user/${userId}/orders`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'PatientOrderHistory', id })),
                          'PatientOrderHistory',
                      ]
                    : ['PatientOrderHistory'],
        }),
        getSquareOrders: builder.query({
            query: (userId) => `/admin/user/${userId}/squareorders/`,
            transformResponse: (response) => {
                return response.orders;
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'PatientSquareOrderHistory', id })),
                          'PatientSquareOrderHistory',
                      ]
                    : ['PatientSquareOrderHistory'],
        }),
        removeOrderItem: builder.mutation({
            query: ({ orderId, userId, orderItemId }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/items/${orderItemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (arg) => [
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
                'Cart',
            ],
        }),
        removeShippingDetails: builder.mutation({
            query: ({ userId, orderId, shippingId }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/shipping/${shippingId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['LocalOrderHistory'],
        }),
        updateOrder: builder.mutation({
            query: ({ orderId, userId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}`,
                method: 'PUT',
                body: { ...values },
            }),
            invalidatesTags: (arg) => [
                { type: 'OrderHistory', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
            ],
        }),
        updateOrderItem: builder.mutation({
            query: ({ orderId, userId, orderItemId, values }) => ({
                url: `/admin/user/${userId}/orders/${orderId}/items/${orderItemId}`,
                method: 'PUT',
                body: { ...values },
            }),
            invalidatesTags: (arg) => [
                { type: 'OrderHistory', id: arg.orderId },
                { type: 'PatientOrderHistory', id: arg.orderId },
                { type: 'OrderInfo', id: arg.orderId },
                'Cart',
            ],
        }),
        updateResupply: builder.mutation({
            query: ({ createdAt, enrollmentStatus, id, nextShipmentDate, userId }) => ({
                url: `/users/${userId}/resupply/${id}`,
                method: 'PUT',
                body: {
                    createdAt: createdAt,
                    enrollmentStatus: enrollmentStatus,
                    nextShipmentDate: nextShipmentDate,
                },
            }),
            providesTags: ['ResupplyStatus'],
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: result.userId }],
        }),
        getPaginatedResupply: builder.query({
            query: ({ whereObject, orderBy, skip, userId }) => {
                return {
                    url: `/users/${userId}/resupply/search`,
                    method: 'POST',
                    body: {
                        skip: skip.toString(),
                        take: '10',
                        where: whereObject ? JSON.stringify({ ...whereObject }) : null,
                        orderBy: JSON.stringify(orderBy),
                    },
                };
            },
            providesTags: (result) => ['ResupplyList'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useAddOrderItemMutation,
    useAddShippingDetailsMutation,
    useCreateOrderMutation,
    useGetOrderInfoQuery,
    useGetPaginatedOrderHistoryQuery,
    useGetPatientOrderHistoryQuery,
    useGetPaginatedResupplyQuery,
    useGetPatientCartQuery,
    useGetSquareOrdersQuery,
    useUpdateOrderItemMutation,
    useUpdateOrderMutation,
    useRemoveOrderItemMutation,
    useRemoveShippingDetailsMutation,
    useUpdateResupplyMutation,
} = adminOrdersAPI;
