import { getBMI } from '../../../utils/constants';
import { api } from '../auth';

const adminAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        updateUser: builder.mutation({
            query: ({ id, ...userData }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: userData,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
        }),
        getAdminUsers: builder.query({
            query: (query) => ({
                url: '/users',
                method: 'POST',
                body: {
                    where: {
                        OR: [...query],
                    },
                    orderBy: { id: 'desc' },
                },
            }),
            providesTags: (result) => ['AdminUsers'],
        }),
        deactivateUser: builder.mutation({
            query: (id) => ({
                url: `/users/${id}/deactivate`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg }, 'AdminUsers'],
        }),
        resendVerificationEmail: builder.mutation({
            query: (data) => ({
                url: `/users/${data.userId}/resend-email-verification`,
                method: 'POST',
                body: { redirectUrl: data.redirectUrl },
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg }],
        }),
        updateAdminUser: builder.mutation({
            query: ({ id, ...userData }) => ({
                url: `/users/${id}/admin`,
                method: 'PUT',
                body: userData,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }, 'AdminUsers'],
        }),
        getUserNotes: builder.query({
            query: ({ userId, skip, take }) =>
                `/notes?where={"userCreatedForId":${userId}}&orderBy={"createdAt":"desc"}&skip=${skip}&take=${take}`,
            providesTags: (result) =>
                result?.results
                    ? [
                          { type: 'Note', id: 'PARTIAL_LIST' },
                          ...result.results.map(({ userCreatedForId }) => ({
                              type: 'Note',
                              id: userCreatedForId,
                          })),
                      ]
                    : [{ type: 'Note', id: 'PARTIAL_LIST' }],
        }),
        createUserNote: builder.mutation({
            query: (noteData) => ({
                url: `/notes`,
                method: 'POST',
                body: noteData,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Note', id: 'PARTIAL_LIST' }],
        }),
        getDiagnoses: builder.query({
            query: () => '/admin/users/diagnoses',
        }),
        updatePatientDiagnoses: builder.mutation({
            query: ({ userId, diagnoses }) => ({
                url: `/admin/users/${userId}/diagnosis`,
                method: 'POST',
                body: diagnoses,
            }),
        }),
        createAdminUser: builder.mutation({
            query: (userData) => ({
                url: '/users/admin',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...userData,
                },
            }),
            invalidatesTags: (result, error, arg) => ['AdminUsers'],
        }),
        twoFactorOverride: builder.mutation({
            query: (data) => ({
                url: '/auth/twoFactorOverride',
                method: 'POST',
                body: { userId: data },
            }),
        }),
        getNewVerificationLink: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}/verification-link`,
                method: 'GET',
                responseHandler: (response) => response.text(),
            }),
        }),

        manualEmailVerification: builder.mutation({
            query: (userId) => ({
                url: '/admin/users/manual-verify',
                method: 'POST',
                body: { userId: userId },
            }),
        }),

        getUser: builder.query({
            queryFn: async (id, api, opts, fetchWithBQ) => {
                const userResult = await fetchWithBQ(`/users/${id}`);

                if (userResult.error) return { error: userResult.error };

                const user = userResult.data;

                if (!user) return userResult;

                if (user.patientProfile?.oralApplianceQuestionnaireResultId) {
                    const oralQuestionnaireResponses = await fetchWithBQ(
                        `/oral-appliance-questionnaire/${user.patientProfile.oralApplianceQuestionnaireResultId}`,
                    );
                    if (oralQuestionnaireResponses.data)
                        user.oralQuestionnaireResponses = oralQuestionnaireResponses.data;
                }

                if (user.patientProfile?.stopBangResultId) {
                    const stopBangResponses = await fetchWithBQ(
                        `/stop-bang/${user.patientProfile.stopBangResultId}`,
                    );

                    if (stopBangResponses.data.heightInches && stopBangResponses.data.weightLbs) {
                        delete stopBangResponses.data.height;
                        delete stopBangResponses.data.weight;

                        stopBangResponses.data.BMI = getBMI(
                            stopBangResponses.data.weightLbs,
                            stopBangResponses.data.heightInches,
                        );
                    }

                    if (stopBangResponses.data) user.stopBangResponses = stopBangResponses.data;
                }

                return { data: user };
            },
            providesTags: (r) => [{ type: 'User', id: r?.id || 'NULL' }],
        }),
        getPatientFeedback: builder.query({
            query: (userId) => ({
                url: `/feedback/user/${userId}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    //Auth
    useCreateAdminUserMutation,
    useGetNewVerificationLinkMutation,
    useGetAdminUsersQuery,
    useDeactivateUserMutation,
    useUpdateAdminUserMutation,
    useTwoFactorOverrideMutation,
    useResendVerificationEmailMutation,
    useManualEmailVerificationMutation,
    //Patient Info
    useGetUserQuery,
    useUpdateUserMutation,
    useGetUserNotesQuery,
    useCreateUserNoteMutation,
    useGetDiagnosesQuery,
    useUpdatePatientDiagnosesMutation,
    useGetPatientFeedbackQuery,
} = adminAPI;
