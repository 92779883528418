import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { setStatusFilter } from '../../../features/admin/adminSlice';
import { adminFilterOptions, adminSelectCustomStyles } from '../../../utils/constants';
import { emailSchema, nameSchema, numberSchema } from '../../../utils/schemas';

const NameEmailIDSearch = ({ setPage, activeFilters }) => {
    const dispatch = useDispatch();
    const { searchTermValue, searchTermType, querySearch } = useSelector(
        (state) => state.admin[activeFilters],
    );

    const [searchType, setSearchType] = useState(searchTermType ? searchTermType : '');
    const [searchTerm, setSearchTerm] = useState(
        searchTermValue ? searchTermValue.basicSearchTerm : '',
    );
    const [lastNameSearchTerm, setLastNameSearchTerm] = useState(
        searchTermValue ? searchTermValue.lastNameSearchTerm : '',
    );
    const [errorMessage, setErrorMessage] = useState('');

    //Validate search term logic
    let schemaToUse = null;
    if (searchType.value === 'nameSearch') {
        schemaToUse = nameSchema;
    } else if (searchType.value === 'emailSearch') {
        schemaToUse = emailSchema;
    } else {
        schemaToUse = numberSchema;
    }

    const validate = (v) => {
        try {
            schemaToUse.validateSync(v);
            setErrorMessage('');
            return true;
        } catch (err) {
            setErrorMessage(err.message);
        }
    };

    //Clears any searches
    function clearSearch() {
        setSearchTerm('');
        setLastNameSearchTerm('');
        dispatch(
            setStatusFilter([
                {
                    type: activeFilters,
                    key: 'searchTermValue',
                    value: null,
                },
                {
                    type: activeFilters,
                    key: 'searchTermType',
                    value: null,
                },
                {
                    type: activeFilters,
                    key: 'skip',
                    value: 0,
                },
            ]),
        );
        setErrorMessage('');
        setPage(0);
    }

    const searchSubmitHandler = async (ev) => {
        ev.preventDefault();

        const validSearch = validate(searchTerm.trim() + lastNameSearchTerm.trim());

        if (validSearch) {
            setPage(0);
            dispatch(
                setStatusFilter([
                    {
                        type: activeFilters,
                        key: 'searchTermValue',
                        value: {
                            basicSearchTerm: searchTerm.trim(),
                            lastNameSearchTerm: lastNameSearchTerm.trim(),
                        },
                    },
                    { type: activeFilters, key: 'searchTermType', value: searchType },
                ]),
            );
        }
    };

    return (
        <div className="w-full p-2">
            {errorMessage && (
                <span className="text-sm font-bold text-red ml-4 pt-4">{errorMessage}</span>
            )}
            <div className="flex flex-wrap items-center">
                <Select
                    options={adminFilterOptions}
                    className="basis-full md:basis-1/4"
                    onChange={(newValue) => {
                        setSearchType(newValue);
                        setSearchTerm('');
                        setLastNameSearchTerm('');
                    }}
                    placeholder={'Select'}
                    styles={adminSelectCustomStyles}
                    cacheOptions
                    //Default to search which is in active filters when returning to page
                    defaultValue={searchTermType}
                />
                <form
                    onSubmit={searchSubmitHandler}
                    className="relative basis-full mt-4 md:mt-0 md:basis-1/2 md:pr-4 md:pl-4">
                    <input
                        name="names"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        placeholder={`Enter a ${
                            searchType?.value === 'nameSearch'
                                ? 'First Name'
                                : searchType
                                  ? searchType?.text
                                  : ''
                        }...`}
                        className={`appearance-none border border-gray bg-transparent p-2 text-sm text-white md:mr-[16px] w-full ${
                            searchType?.value === 'nameSearch'
                                ? 'md:w-[calc(33%-16px)]'
                                : 'md:w-[calc(66%-16px)]'
                        }`}
                    />
                    {searchType.value === 'nameSearch' && (
                        <input
                            name="last-name"
                            type="text"
                            value={lastNameSearchTerm}
                            onChange={(e) => setLastNameSearchTerm(e.currentTarget.value)}
                            placeholder={`Enter a ${
                                searchType.value === 'nameSearch'
                                    ? 'Last Name'
                                    : searchType
                                      ? searchType?.text
                                      : ''
                            }...`}
                            className="appearance-none border border-gray bg-transparent p-2 text-sm text-white md:mr-[16px] w-full md:w-[calc(33%-16px)] mt-4 md:mt-0"
                        />
                    )}
                    <button
                        type="submit"
                        disabled={
                            (searchTerm?.length < 1 && lastNameSearchTerm.length < 1) ||
                            !searchType ||
                            querySearch
                        }
                        className="btn-primary-small w-1/2 mt-4 md:mt-0 md:w-1/3 text-base lg:text-lg basis-1/2">
                        Search
                    </button>
                </form>
                <button
                    //Disabled when there is no entry, or when a custom query has been sent
                    disabled={(!searchTerm && !lastNameSearchTerm) || querySearch}
                    onClick={() => clearSearch(true)}
                    className="btn-secondary-small basis-1/2 mt-4 md:mt-0 md:basis-1/6 lg:basis-[19%] text-base lg:text-lg">
                    Clear
                </button>
            </div>
        </div>
    );
};

export default NameEmailIDSearch;
