import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedLayout, UnauthenticatedLayout } from './components';
import * as Views from './views';

function App() {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
        testMode: window.location.origin === 'https://local.gem.health:3000',
        debug: window.location.origin === 'https://local.gem.health:3000',
    });
    const GEMRoutes =
        process.env.REACT_APP_ENVIRONMENT !== 'local'
            ? Sentry.withSentryReactRouterV6Routing(Routes)
            : Routes;

    return (
        <GEMRoutes>
            <Route path="/" element={<UnauthenticatedLayout />}>
                <Route index element={<Views.AssessmentHome />} />
                <Route path="partner/:partnerId" element={<Views.AssessmentHome />} />
                <Route
                    path="dental/:dentalPracticeId"
                    element={<Views.AssessmentDentalReferral />}
                />
                <Route path="assessment" element={<Views.Assessment />} />
                <Route path="sign-in" element={<Views.SignIn />} />
                <Route path="reset-password" element={<Views.ResetPassword />} />
                <Route path="account-created" element={<Views.AccountCreationSuccess />} />
                <Route path="mask-comparison" element={<Views.MaskComparison />} />
                <Route path="terms/:termsSlug" element={<Views.Terms />} />
            </Route>

            <Route path="/admin" element={<Views.Admin />}>
                <Route index element={<Views.Admin.Home />} />
                <Route path="administrators" element={<Views.Admin.Administrators />} />
                <Route path="administrators/:userId" element={<Views.Admin.User />} />
                <Route path="administrators/create-user" element={<Views.Admin.CreateUser />} />
                <Route path="patients/:patientId" element={<Views.Admin.Patient />} />
                <Route path="patients/:patientId/:gemTab" element={<Views.Admin.Patient />} />
                <Route
                    path="patients/:patientId/:gemTab/:subTab"
                    element={<Views.Admin.Patient />}
                />
                <Route path="consults" element={<Views.Admin.Consults />} />
                <Route path="consults/:consultTab" element={<Views.Admin.Consults />} />
                <Route path="state-services" element={<Views.Admin.StateServices />} />
                <Route path="shop" element={<Views.Admin.Shop />} />
                <Route path="orders/" element={<Views.Admin.Orders />} />
                <Route path="orders/:ordersTab" element={<Views.Admin.Orders />} />
                <Route path="organizations" element={<Views.Admin.Organizations />} />
                <Route path="organizations/:orgTab" element={<Views.Admin.Organizations />} />
                <Route
                    path="organizations/update/:partnerId/"
                    element={<Views.Admin.UpdatePartner />}
                />
                <Route
                    path="patients/:patientId/orders/:orderId"
                    element={<Views.Admin.OrderInformation />}
                />
                <Route path="payers" element={<Views.Admin.Payers />} />
                <Route path="reports" element={<Views.Admin.Reports />} />
                <Route path="care-team" element={<Views.Admin.CareTeam />} />
            </Route>

            <Route path="/dashboard" element={<AuthenticatedLayout />}>
                <Route index element={<Views.Home />} />
                <Route path="diagnosis" element={<Views.Diagnosis />}>
                    <Route index element={<Views.Diagnosis.Home />} />
                    <Route path="get-diagnosed" element={<Views.Diagnosis.GetDiagnosed />} />
                    <Route
                        path="study-in-review"
                        element={<Views.Diagnosis.SleepStudyInReview />}
                    />
                    <Route path="study-upload" element={<Views.Diagnosis.SleepStudyUpload />} />
                    <Route path="gem-sleep-study" element={<Views.Diagnosis.GemSleepStudy />} />
                </Route>
                <Route path="resources" element={<Views.Resources />}>
                    <Route index element={<Views.Resources.Home />} />
                    <Route path="blog/:articleSlug" element={<Views.Resources.Article />} />
                </Route>

                <Route path="appointments" element={<Views.Appointments />}>
                    <Route index element={<Views.Appointments.MyAppointment />} />
                    <Route
                        path="reschedule-appointment"
                        element={<Views.Appointments.RescheduleAppointment />}
                    />
                    <Route
                        path="appointment-confirmed"
                        element={<Views.Appointments.AppointmentConfirmed />}
                    />
                    <Route path="my-appointment" element={<Views.Appointments.MyAppointment />} />
                    <Route path="overview" element={<Views.Appointments.Overview />} />
                    <Route
                        path="dental-appointment"
                        element={<Views.Appointments.DentalAppointment />}
                    />
                </Route>

                <Route path="treatment" element={<Views.Treatment />}>
                    <Route index element={<Views.Treatment.Home />} />
                    <Route
                        path="dentist-confirmation"
                        element={<Views.Treatment.DentistConfirmation />}
                    />
                    <Route
                        path="treatment-options"
                        element={<Views.Treatment.PreRecommendations />}
                    />
                    <Route path="gem-bundle" element={<Views.Treatment.BundleDescription />} />
                    <Route path="bundle/:bundleId" element={<Views.Treatment.BundleDetails />} />
                    <Route path="comparison" element={<Views.Treatment.ComparisonCharts />} />

                    {/* <Route path="shop" element={<Views.Shop />} />
					<Route path="shop/:productSlug" element={<Views.Product />} /> */}
                </Route>

                <Route path="checkout" element={<Views.Checkout />}></Route>

                <Route path="insurance" element={<Views.Insurance />}>
                    {/* <Route index element={<Views.Insurance.CurrentInsurance />} /> */}
                    <Route path="add" element={<Views.Insurance.AddInsurance />} />
                    {/* <Route path="update" element={<Views.Insurance.UpdateInsurance />} /> */}
                    {/* <Route path="upload" element={<Views.Insurance.UploadCard />} /> */}
                </Route>

                <Route path="care-team" element={<Views.CareTeam />} />

                <Route path="*" element={<Views.ErrorPage theme="light" />} />
            </Route>

            <Route path="*" element={<Views.ErrorPage />} />
        </GEMRoutes>
    );
}

export default App;
