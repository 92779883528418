import { Link } from 'react-router-dom';
import { ReactComponent as TopRightArrow } from '../../images/top-right-arrow.svg';
import watchpatOneSrc from '../../images/sleep-study-product/watchpat-one.png';

function NeedANewSleepStudy() {
    return (
        <div className="grid grid-cols-[121px_1fr] gap-4">
            <img
                src={watchpatOneSrc}
                className="bg-white max-w-[121px] sm:row-span-4"
                alt="Sleep Test"
            />
            <h5 className="font-heading text-4xl lg:text-5xl">Need a new sleep study?</h5>
            <p className="mb-1 text-[13px] leading-normal col-span-2 sm:col-span-1 lg:text-base">
                If your previous sleep study does not meet the minimum requirements (including
                containing a prescription and dated within the last 5 years), we can help get you a
                new home sleep study through GEM SLEEP.
            </p>
            <p className="mb-1 text-[13px] leading-normal col-span-2 sm:col-span-1 lg:text-base">
                Our home sleep study can be done with just one night of sleep -- no cords, no wires,
                no lab -- the entire study is done completely at home.
            </p>
            <div className="items-center col-span-2 sm:col-span-1 space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                <Link
                    to="/dashboard/diagnosis/gem-sleep-study"
                    className="btn-primary flex items-center justify-between space-x-4">
                    <span>GET AN AT-HOME SLEEP STUDY</span>
                    <TopRightArrow className="scale-150" />
                </Link>
            </div>
        </div>
    );
}

export default NeedANewSleepStudy;
