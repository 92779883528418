import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useChangeDocumentStatusMutation,
    useGetPatientFilesQuery,
    useUpdateDocumentFileTypeMutation,
    useUpdateDocumentNotesMutation,
} from '../../../app/services/admin/documents';
import { Modal, UploadFile } from '../..';
import { ReactComponent as CheckCircle } from '../../../images/icons/check-circle.svg';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import { ReactComponent as SettingsIcon } from '../../../images/icons/settings.svg';
import { ReactComponent as Upload } from '../../../images/icons/upload.svg';
import { formatDate } from '../../../utils/constants';
import './documents.scss';

const DocumentsView = ({ patient, onDocumentsChangeSuccess }) => {
    const { storageToken } = useSelector((state) => state.auth);
    const downloadBaseURL = process.env.REACT_APP_API_BASE_URL
        ? `${process.env.REACT_APP_API_BASE_URL}/files`
        : '/files';
    const { data: patientFiles, refetch } = useGetPatientFilesQuery(patient.id);
    const [
        changeDocumentStatus,
        { isSuccess: onDocumentStatusChangeSuccess, isError: onDocumentStatusChangeError },
    ] = useChangeDocumentStatusMutation();
    const [
        updateDocumentFileType,
        { isSuccess: onDocumentUpdateSuccess, isError: onDocumentUpdateError },
    ] = useUpdateDocumentFileTypeMutation();
    const [
        updateDocumentNotes,
        { isSuccess: onDocumentNotesUpdateSuccess, isError: onDocumentNotesUpdateError },
    ] = useUpdateDocumentNotesMutation();
    const [notesUpdateTimer, setNotesUpdateTimer] = useState(null);
    const [showUpdateFeedback, setShowUpdateFeedback] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFileType, setUploadFileType] = useState('');

    const fileTypes = [
        {
            title: 'Uploaded Sleep Studies',
            singular: 'Uploaded Sleep Study',
            type: 'UPLOADED_SLEEP_STUDY',
        },
        {
            title: 'Itamar Sleep Studies',
            singular: 'Itamar Sleep Study',
            type: 'ITAMAR_SLEEP_STUDY',
        },
        {
            title: 'Written Order (Referral) Documents',
            singular: 'Written Order (Referral) Document',
            type: 'REFERRAL',
        },
        {
            title: 'Prescriptions',
            singular: 'Prescription',
            type: 'PRESCRIPTION',
        },
        // it would be better if each section could have an array of types, or no sections at all just a file list
        {
            title: 'Insurance Card Front',
            singular: 'Insurance Card Front',
            type: 'INSURANCE_CARD_FRONT',
        },
        {
            title: 'Insurance Card Back',
            singular: 'Insurance Card Back',
            type: 'INSURANCE_CARD_BACK',
        },
        {
            title: 'Other Documents',
            singular: 'Other Document',
            type: 'OTHER',
        },
    ];

    const fileStatusOptions = [
        {
            label: 'In Review',
            value: 'IN_REVIEW',
        },
        {
            label: 'Approved',
            value: 'APPROVED',
        },
        {
            label: 'Rejected',
            value: 'REJECTED',
        },
    ];

    const handleDocumentStatusChange = async (fileId, status) => {
        await changeDocumentStatus({ fileId, status, patientId: patient.id, storageToken });
        onDocumentsChangeSuccess();
    };

    const handleUpdateFileType = async (fileId, fileType) => {
        await updateDocumentFileType({ fileId, fileType, patientId: patient.id, storageToken });
        onDocumentsChangeSuccess();
    };

    const onNotesUpdate = (notes) => {
        clearTimeout(notesUpdateTimer);
        setNotesUpdateTimer(
            window.setTimeout(() => {
                updateDocumentNotes({
                    fileId: selectedFile.id,
                    notes,
                    patientId: patient.id,
                    storageToken,
                });
            }, 1500),
        );
    };

    useEffect(() => {
        if (patient) {
            refetch();
        }
    }, [patient, refetch]);

    useEffect(() => {
        if (
            onDocumentStatusChangeSuccess ||
            onDocumentUpdateSuccess ||
            onDocumentNotesUpdateSuccess
        ) {
            setUpdateSuccess(true);
            setShowUpdateFeedback(true);
        } else if (
            onDocumentStatusChangeError ||
            onDocumentUpdateError ||
            onDocumentNotesUpdateError
        ) {
            setUpdateSuccess(false);
            setShowUpdateFeedback(true);
        }
    }, [
        onDocumentStatusChangeSuccess,
        onDocumentUpdateSuccess,
        onDocumentNotesUpdateSuccess,
        onDocumentNotesUpdateError,
        onDocumentStatusChangeError,
        onDocumentUpdateError,
    ]);

    useEffect(() => {
        if (showUpdateFeedback) {
            setTimeout(() => {
                setShowUpdateFeedback(false);
            }, 1000);
        }
    }, [showUpdateFeedback]);

    return (
        <div className={'space-y-12'}>
            <header className="mb-12 flex justify-between">
                <h2 className="font-heading text-5xl">Patient Documents</h2>
                <div className="documents-actions">
                    <button
                        className="btn-primary upload-new-document-btn flex"
                        onClick={() => {
                            setUploadFileType('');
                            setAddModalOpen(true);
                        }}>
                        Upload New Document <Upload />
                    </button>
                </div>
            </header>

            <section>
                {fileTypes.map((fileType, fileTypeIndex) => (
                    <div
                        className="file-type p-8"
                        key={`${fileType.type
                            .toLowerCase()
                            .replaceAll('_', '-')}-${fileTypeIndex}`}>
                        <h3 className="mb-4 text-lg">
                            <strong>{fileType.title}</strong>
                        </h3>
                        <ul
                            className={`file-list ${fileType.type
                                .toLowerCase()
                                .replaceAll('_', '-')}`}>
                            {patientFiles
                                ?.filter((file) => file.fileType === fileType.type)
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                .map((file, index) => (
                                    <li
                                        key={`${file.fileType}-${index}`}
                                        className={`file-block ${file.status.toLowerCase()}`}>
                                        <div className="file-block-content">
                                            {file.status === 'APPROVED' && (
                                                <CheckCircle className={`file-approved-icon`} />
                                            )}
                                            <p className="data-pair">
                                                <label className="font-bold">Filename: </label>
                                                <span className="opacity-80">{file.fileName}</span>
                                            </p>
                                            <p className="data-pair">
                                                <label className="font-bold">Create At: </label>
                                                <span className="opacity-80">
                                                    {formatDate(file.createdAt)}
                                                </span>
                                            </p>
                                            <div className="file-actions">
                                                <a
                                                    className="border-white-500 file-download-btn inline-flex items-center rounded border bg-transparent py-1 px-3 text-base"
                                                    href={`${downloadBaseURL}/${file.id}?storageToken=${storageToken}`}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    Download
                                                    <Download />
                                                </a>

                                                <button
                                                    className="transparent rounded text-base lg:text-lg"
                                                    onClick={() => {
                                                        setSelectedFile({ ...file });
                                                        setEditModalOpen(true);
                                                    }}>
                                                    <SettingsIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                        {patientFiles?.filter((file) => file.fileType === fileType.type).length ===
                            0 && <p className="opacity-50">No files found.</p>}
                    </div>
                ))}
            </section>
            <Modal
                showClose={true}
                isOpen={addModalOpen}
                closeHandler={() => setAddModalOpen(false)}>
                <div className="add-file-modal-content">
                    <h2 className="mb-4 text-3xl font-bold">Upload New Document</h2>
                    <select
                        value={uploadFileType}
                        onChange={(e) => {
                            setUploadFileType(e.target.value);
                        }}
                        className="w-full border-b border-b-gray-light bg-transparent hover:cursor-pointer">
                        <option value="">Please Select File Type</option>
                        {fileTypes.map((opt, idx) => (
                            <option key={`${opt.type}-${idx}`} value={opt.type}>
                                {opt.singular}
                            </option>
                        ))}
                    </select>
                    <div className="mt-6">
                        <UploadFile
                            isModal={true}
                            fileType={uploadFileType}
                            patientId={patient.id}
                            uploadSuccessCallback={(fileId) => {
                                onDocumentsChangeSuccess();
                                // reset filetype
                                window.setTimeout(() => {
                                    setAddModalOpen(false);
                                }, 2000);
                            }}
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                showClose={true}
                isOpen={editModalOpen}
                closeHandler={() => setEditModalOpen(false)}>
                <h2 className="mb-4 text-3xl font-bold">
                    Edit Document: <span>{selectedFile?.fileName}</span>
                </h2>
                {selectedFile && (
                    <div className="edit-document-actions flex justify-between">
                        {selectedFile.fileType === 'UPLOADED_SLEEP_STUDY' && (
                            <div className="file-status-selector">
                                <label className="mb-2 font-bold">Change File Status:</label>
                                <select
                                    className="px-4"
                                    value={selectedFile.status}
                                    onChange={(e) => {
                                        handleDocumentStatusChange(selectedFile.id, e.target.value);
                                        setSelectedFile({
                                            ...selectedFile,
                                            status: e.target.value,
                                        });
                                    }}>
                                    {fileStatusOptions.map((option, index) => (
                                        <option key={`status-option-${index}`} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="filetype-selector">
                            <label className="mb-2 font-bold">Change File Type:</label>
                            <select
                                className="px-4"
                                value={selectedFile.fileType}
                                onChange={(e) => {
                                    const fileType = e.target.value;

                                    handleUpdateFileType(selectedFile.id, fileType);
                                    setSelectedFile({ ...selectedFile, fileType });
                                }}
                                required>
                                {fileTypes.map((opt, idx) => (
                                    <option key={`${opt.type}-${idx}`} value={opt.type}>
                                        {opt.singular}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="file-notes">
                            <label className="mb-2 font-bold">Notes:</label>
                            <textarea
                                className="textarea"
                                rows="4"
                                value={selectedFile.notes || ''}
                                onChange={(e) => {
                                    onNotesUpdate(e.target.value);
                                    setSelectedFile({ ...selectedFile, notes: e.target.value });
                                }}></textarea>
                        </div>
                    </div>
                )}
                <div className={`saved-indicator ${showUpdateFeedback ? 'show' : ''}`}>
                    {updateSuccess && <p className="success-msg font-bold">FILE SAVED</p>}
                    {!updateSuccess && <p className="error-msg font-bold">ERROR SAVING FILE</p>}
                </div>
            </Modal>
        </div>
    );
};

export default DocumentsView;
