import { Formik, Form } from 'formik';
import { useUpdateMeMutation } from '../../app/services/auth';
import { ReactComponent as LockIcon } from '../../images/icons/lock.svg';
import { CustomInput, CustomReactSelect } from '../InputComponents';
import { states } from '../../utils/constants';
import { updateProfileSchema } from '../../utils/schemas';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { toggle } from '../../features/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';

const AccountEditContent = ({ cancelClickHandler }) => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const defaultValues = {};
    const allowedUpdateFields = [
        'firstName',
        'lastName',
        'email',
        'phone',
        'street1',
        'street2',
        'city',
        'state',
        'zip',
    ];
    const [updateMe] = useUpdateMeMutation();

    // Initialize user form values
    Object.keys(user).forEach((k) => {
        if (allowedUpdateFields.indexOf(k) >= 0) defaultValues[k] = user[k];
    });

    const initialValues = {
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        street1: user.street1 || '',
        street2: user.street2 || '',
        city: user.city || '',
        state: user.state || '',
        zip: user.zip || '',
    };

    const submitHandler = async (values) => {
        const payload = {};

        Object.keys(values)
            .filter((key, i) => values[key] !== initialValues[key])
            .forEach((k) => {
                payload[k] = values[k];
            });

        await updateMe(payload);

        dispatch(toggle('editScreenOpen'));
    };

    return (
        <>
            <h5 className="relative mb-2 xs:mb-6 flex items-center justify-between">
                <span className="font-heading text-4xl font-bold lg:text-5xl">Edit details</span>
                <button
                    onClick={() => dispatch(toggle('editScreenOpen'))}
                    className="flex items-center font-heading text-sm font-bold">
                    <span className="tracking-widest">Cancel</span>
                    <span className="relative ml-2 aspect-1 w-6 rounded-full border-2 border-green-400">
                        <CloseIcon className="absolute-center" />
                    </span>
                </button>
            </h5>

            <Formik
                validationSchema={updateProfileSchema}
                initialValues={initialValues}
                onSubmit={submitHandler}
                enableReinitialize={true}>
                {({ dirty, isValid, isSubmitting }) => (
                    <Form className="space-y-2 xl:space-y-3">
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                First name
                            </span>
                            <CustomInput
                                theme="light"
                                name="firstName"
                                label="First Name"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Last name
                            </span>
                            <CustomInput
                                theme="light"
                                name="lastName"
                                label="Last Name"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Email
                            </span>
                            <p className="pointer-events-none font-bold opacity-60 lg:text-lg">
                                {user.email}
                            </p>
                        </div>
                        <div className="flex items-center justify-between">
                            <div>
                                <label className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                    Password
                                </label>
                                <p className="font-bold lg:text-lg">•••••••••••</p>
                            </div>
                            <button
                                type="button"
                                className="btn-shell flex items-center lg:py-4"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    dispatch(toggle('passwordResetScreenOpen'));
                                }}>
                                <span className="text-sm">Reset password</span>
                                <LockIcon className="ml-3" />
                            </button>
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Phone
                            </span>
                            <CustomInput
                                theme="light"
                                name="phone"
                                label="Phone"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Address line 1
                            </span>
                            <CustomInput
                                theme="light"
                                name="street1"
                                label="Address line 1"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Address line 2
                            </span>
                            <CustomInput
                                theme="light"
                                name="street2"
                                label="Address line 2"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                City
                            </span>
                            <CustomInput
                                theme="light"
                                id="city"
                                name="city"
                                label="City"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                State
                            </span>
                            <CustomReactSelect
                                theme="light"
                                id="state"
                                name="state"
                                placeholder="State"
                                options={states}
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <div>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Zip
                            </span>
                            <CustomInput
                                theme="light"
                                name="zip"
                                label="Zip"
                                className="border-b border-b-gray-dark lg:text-lg"
                            />
                        </div>
                        <button
                            className="btn-primary w-full"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}>
                            Save changes
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AccountEditContent;
