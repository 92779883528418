import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Partners } from '../../components/Admin/Organizations';
import { ReferralsList } from '../../components/Admin/Referrals';
import CreatePartner from './CreatePartner';

function Organizations() {
    const navigate = useNavigate();
    const { orgTab } = useParams();

    useEffect(() => {
        if (!orgTab) {
            navigate('list', { replace: true });
        }
    }, [navigate, orgTab]);

    return (
        <>
            <h2 className="mb-5 pt-6 flex items-center justify-between">
                <span className="font-heading text-5xl">Organizations</span>
                <Link
                    to="/admin/organizations/create"
                    className="border-b-2 border-gem-green font-bold">
                    Add a new organization
                </Link>
            </h2>
            <nav className="my-4">
                <button
                    onClick={(ev) => {
                        navigate(`/admin/organizations/list`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${orgTab === 'list' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Organization List
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`/admin/organizations/referrals`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${orgTab === 'referrals' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Referrals
                </button>
            </nav>
            {orgTab === 'list' && <Partners />}
            {orgTab === 'referrals' && <ReferralsList />}
            {orgTab === 'create' && <CreatePartner />}
        </>
    );
}

export default Organizations;
