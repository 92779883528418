import { useEffect, useState } from 'react';
import Select from 'react-select';
//Can use react-us-map in future for more versatility
import USAMap from 'react-usa-map';
import {
    useGetAppointmentTypesQuery,
    useGetAvailibilityQuery,
} from '../../../app/services/appointments';
import { useGetPayersQuery } from '../../../app/services/insurance';
import { adminSelectCustomStyles, lerpColor, states } from '../../../utils/constants';
import { useWindowSize } from '../../../utils/useWindowSize';
import { useNavigate } from 'react-router-dom';

const AvailabilityMap = () => {
    const navigate = useNavigate();

    //Queries
    const { data: availability, isLoading: availabilityLoading } = useGetAvailibilityQuery();
    const { data: payers, isLoading: payorLoading } = useGetPayersQuery();
    const { data: appointmentTypes, isLoading: appiontmentTypesLoading } =
        useGetAppointmentTypesQuery();

    //Local State
    const [statesData, setStatesData] = useState({});
    const [payerId, setPayerId] = useState(null);
    const [appointmentTypeId, setAppointmentTypeId] = useState(null);

    //Navigate to Availability page and set search params based on selections on Map page
    const mapHandler = (event) => {
        navigate(
            `/admin/consults/availability?state=${event.target.dataset.name}&payerId=${payerId}&appointmentTypeId=${appointmentTypeId}`,
        );
    };

    const payerOptions = payers?.filter((payer) => payer.enabled).map((payer) => ({ value: payer.id, label: payer.name }));
    const appointmentTypeOptions = appointmentTypes?.map((apptType) => ({
        value: apptType.id,
        label: apptType.name,
    }));

    //Use window resizing to rerender map
    const { width, height } = useWindowSize();

    useEffect(() => {
        function setStateAvailability(usState, statesData, appointmentTypeId) {
            let totalSlots = 0;
            availability.forEach((provider) => {
                if (
                    !appointmentTypeId ||
                    (appointmentTypeId &&
                        provider.appointmentTypes.find(
                            (apptType) => apptType.id === appointmentTypeId,
                        ))
                ) {
                    provider.states.forEach((providerState) => {
                        if (providerState === usState.value) {
                            totalSlots += provider.slots.length;
                        }
                    });
                }
            });
            //If there are at least 10 available slots in the state, color it based on number of slots trending blue
            if (totalSlots > 10) {
                statesData[usState.value] = {
                    fill: lerpColor('#53e678', '#013b10', Math.min(1, totalSlots / 100)),
                };
            }
        }
        if (availability && payers) {
            const statesData = {};
            for (const usState of states) {
                if (payerId !== null) {
                    const activePayer = payers.find((payer) => payer.id === payerId);
                    if (activePayer.serviceStates.includes(usState.value)) {
                        //If the state is serviced by the payer, color it red initially
                        statesData[usState.value] = { fill: '#8f1f27' };
                        //Rewrite the color if there are more than 10 available slots
                        setStateAvailability(usState, statesData, appointmentTypeId);
                    }
                } else {
                    //Otherwise default gray is colored for states with more than 10 available slots
                    statesData[usState.value] = { fill: '#8f1f27' };
                    setStateAvailability(usState, statesData, appointmentTypeId);
                }
            }
            setStatesData(statesData);
        }
    }, [
        payers,
        payorLoading,
        availability,
        availabilityLoading,
        payerId,
        appointmentTypeId,
        appiontmentTypesLoading,
    ]);

    if (payorLoading || availabilityLoading) {
        return <p>Loading...</p>;
    } else {
        return (
            <div className="flex flex-col gap-y-2">
                <p className="text-xl xs:text-3xl self-center font-heading tracking-widest">
                    Seven Day Availability
                </p>
                <div className="flex flex-col lg:flex-row space-x-2 items-center mb-2 self-center lg:pr-[190px]">
                    <p className="w-[180px] text-center text-gray lg:text-right">Payer:</p>
                    <Select
                        isClearable
                        options={payerOptions}
                        className="w-[250px]"
                        onChange={(selectedOption) =>
                            setPayerId(selectedOption ? selectedOption.value : null)
                        }
                        placeholder={'Select Payer'}
                        styles={adminSelectCustomStyles}
                        value={payerOptions?.find((payer) => payer.value === payerId) ?? null}
                    />
                </div>
                <div className="flex flex-col lg:flex-row space-x-2 items-center mb-4 self-center lg:pr-[190px]">
                    <p className="w-[180px] text-center text-gray lg:text-right">
                        Appointment Type:
                    </p>
                    <Select
                        isClearable
                        options={appointmentTypeOptions}
                        className="w-[250px]"
                        onChange={(selectedOption) =>
                            setAppointmentTypeId(selectedOption ? selectedOption.value : null)
                        }
                        placeholder={'Select Appt Type'}
                        styles={adminSelectCustomStyles}
                        value={
                            appointmentTypeOptions?.find(
                                (apptType) => apptType.value === appointmentTypeId,
                            ) ?? null
                        }
                    />
                </div>
                <div className="self-center">
                    <USAMap
                        defaultFill={'#2B2F3A'}
                        width={0.75 * width}
                        height={Math.min(0.75 * width, 0.5 * height)}
                        title={'Availability Map'}
                        customize={statesData}
                        onClick={mapHandler}
                    />
                </div>
                <div className="self-center flex space-x-2 md:mt-4">
                    <p className="self-center">
                        Less <span className="hidden md:inline">Availability</span>
                    </p>
                    <div className="w-[200px] border bg-gradient-to-r from-[#53e678] to-[#013b10]"></div>
                    <p className="self-center">
                        More <span className="hidden md:inline">Availability</span>
                    </p>
                </div>
                <div className="self-center flex space-x-2 mb-4">
                    <p>Under 10 available appointments</p>
                    <div className="w-[25px] border bg-[#8f1f27]"></div>
                </div>
            </div>
        );
    }
};

export default AvailabilityMap;
