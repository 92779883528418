import { api } from '../auth';
import { postPaginatedPatientQuery } from '../../../utils/constants';

function providesPartialList(resultsWithIds, tagType) {
    return resultsWithIds?.results
        ? [
              { type: tagType, id: 'PARTIAL_LIST' },
              ...resultsWithIds.results.map(({ id }) => ({ type: tagType, id })),
          ]
        : [{ type: tagType, id: 'PARTIAL_LIST' }];
}

const searchAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        deleteAdminQuery: builder.mutation({
            query: (id) => ({
                url: `/admin/queries/${id}`,
                method: 'DELETE',
                body: { id: id },
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Queries' }],
        }),
        getAdminQueries: builder.query({
            query: () => ({
                url: '/admin/queries',
                method: 'GET',
            }),
            providesTags: (result) => providesPartialList(result, 'Queries'),
        }),
        getUsers: builder.query({
            query: ({
                accountCreationStatus,
                appointments,
                careTeam,
                cpapOrderStatus,
                dentalReferralStatus,
                homeSleepStudyStatus,
                initialCompliance,
                insurance,
                isDentalReferred,
                mdStatus,
                needsSleepStudyRetest,
                orderBy,
                querySearch,
                referFromOrganization,
                referredForCpap,
                referToSleepClinic,
                resupply,
                searchTermValue,
                searchTermType,
                skip,
                supplyOrdersStatus,
                state,
                take,
            }) => {
                const formattedQuery = postPaginatedPatientQuery({
                    accountCreationStatus,
                    appointments,
                    careTeam,
                    cpapOrderStatus,
                    dentalReferralStatus,
                    homeSleepStudyStatus,
                    initialCompliance,
                    insurance,
                    isDentalReferred,
                    mdStatus,
                    needsSleepStudyRetest,
                    orderBy,
                    querySearch,
                    referFromOrganization,
                    referredForCpap,
                    referToSleepClinic,
                    resupply,
                    searchTermType,
                    searchTermValue,
                    skip,
                    supplyOrdersStatus,
                    state,
                    take,
                });
                return {
                    url: '/users',
                    method: 'POST',
                    body: formattedQuery,
                };
            },
            providesTags: (result) => providesPartialList(result, 'User'),
        }),
        postAdminQuery: builder.mutation({
            query: ({
                accountCreationStatus,
                appointments,
                careTeam,
                cpapOrderStatus,
                dentalReferralStatus,
                homeSleepStudyStatus,
                initialCompliance,
                insurance,
                isDentalReferred,
                mdStatus,
                needsSleepStudyRetest,
                orderBy,
                querySearch,
                referFromOrganization,
                referredForCpap,
                referToSleepClinic,
                resupply,
                searchTermValue,
                searchTermType,
                skip,
                supplyOrdersStatus,
                state,
                take,
                //Query specific values
                userId,
                queryName,
                publicQuery,
            }) => {
                const queryToPost = postPaginatedPatientQuery({
                    accountCreationStatus,
                    appointments,
                    careTeam,
                    cpapOrderStatus,
                    dentalReferralStatus,
                    homeSleepStudyStatus,
                    initialCompliance,
                    insurance,
                    isDentalReferred,
                    mdStatus,
                    needsSleepStudyRetest,
                    orderBy,
                    querySearch,
                    referFromOrganization,
                    referredForCpap,
                    referToSleepClinic,
                    resupply,
                    searchTermValue,
                    searchTermType,
                    skip,
                    supplyOrdersStatus,
                    state,
                    take,
                });
                return {
                    url: '/admin/queries',
                    method: 'POST',
                    body: {
                        userId: userId,
                        queryName: queryName,
                        queryString: queryToPost,
                        publicQuery: publicQuery,
                    },
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Queries', id: 'PARTIAL_LIST' }],
        }),
        updateAdminQuery: builder.mutation({
            query: ({
                accountCreationStatus,
                appointments,
                careTeam,
                cpapOrderStatus,
                dentalReferralStatus,
                homeSleepStudyStatus,
                initialCompliance,
                insurance,
                isDentalReferred,
                mdStatus,
                needsSleepStudyRetest,
                orderBy,
                querySearch,
                referFromOrganization,
                referredForCpap,
                referToSleepClinic,
                resupply,
                searchTermValue,
                searchTermType,
                skip,
                supplyOrdersStatus,
                state,
                take,
                //Query specific values
                userId,
                queryName,
                queryId,
            }) => {
                const queryToPost = postPaginatedPatientQuery({
                    accountCreationStatus,
                    appointments,
                    careTeam,
                    cpapOrderStatus,
                    dentalReferralStatus,
                    homeSleepStudyStatus,
                    initialCompliance,
                    insurance,
                    isDentalReferred,
                    mdStatus,
                    needsSleepStudyRetest,
                    orderBy,
                    querySearch,
                    referFromOrganization,
                    referredForCpap,
                    referToSleepClinic,
                    resupply,
                    searchTermValue,
                    searchTermType,
                    skip,
                    supplyOrdersStatus,
                    state,
                    take,
                });
                return {
                    url: `/admin/queries/${queryId}`,
                    method: 'PUT',
                    body: {
                        userId: userId,
                        queryName: queryName,
                        queryString: queryToPost,
                        publicQuery: false,
                    },
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdminQueriesQuery,
    useGetUsersQuery,
    useDeleteAdminQueryMutation,
    usePostAdminQueryMutation,
    useUpdateAdminQueryMutation,
} = searchAPI;
