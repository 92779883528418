import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useGetAdminUsersQuery } from '../../app/services/admin/admin';
import {
    useGetBillingProvidersQuery,
    useGetDmeReferralDestinationsQuery,
    useGetItamarInterpretationPoolsQuery,
    useGetServiceLocationsQuery,
} from '../../app/services/admin/insurance';
import { useGetPayersQuery } from '../../app/services/insurance';
import { NikoPayers, PayerInfo } from '../../components/Admin/Payers';
import {
    EditPayerInformation,
    EditPayerProviders,
    EditPriorAuthRequirements,
} from '../../components/Admin/Payers/EditPayerModals';

const payerTypes = [
    {
        label: 'Insurance',
        value: 'INSURANCE',
    },
    {
        label: 'Employer',
        value: 'EMPLOYER',
    },
    {
        label: 'Sponsor',
        value: 'SPONSOR',
    },
    {
        label: 'Other',
        value: 'OTHER',
    },
];

function HorizontalLine({ styling }) {
    return <div className={`border-b w-full col-span-4 my-2 ${styling}`}></div>;
}

const Payers = () => {
    //Local state values
    const [editingPayer, setEditingPayer] = useState(null);
    const [expandedPayer, setExpandedPayer] = useState(null);
    const [modalOpen, setModalOpen] = useState(null);
    const [viewNikoPayers, setViewNikoPayers] = useState(false);

    //Queries and Mutations
    const { data: payers, isLoading: payersLoading } = useGetPayersQuery();
    const { data: serviceLocations, isLoading: serviceLocationsLoading } =
        useGetServiceLocationsQuery();
    const { data: billingProviders, isLoading: billingProvidersLoading } =
        useGetBillingProvidersQuery();
    const { data: dmeReferralDestinations, isLoading: dmeLoading } =
        useGetDmeReferralDestinationsQuery();
    const { isLoading: itamarLoading } = useGetItamarInterpretationPoolsQuery();

    const adminUserQuery = [{ userRoles: { some: { role: 'MD' } } }];
    const { isLoading: membersLoading } = useGetAdminUsersQuery(adminUserQuery);

    if (
        payersLoading ||
        dmeLoading ||
        itamarLoading ||
        membersLoading ||
        serviceLocationsLoading ||
        billingProvidersLoading
    )
        return <p className="py-6 font-heading text-5xl">Loading...</p>;

    return (
        <>
            <h2 className="mb-10 flex items-center justify-between my-6">
                <span className="font-heading text-5xl">Payers</span>
                <button
                    className="border-b-2 border-gem-green font-bold"
                    disabled={false} // enable if removeable(no linked insurance plans)
                    onClick={(event) => {
                        setEditingPayer({ name: '' });
                    }}>
                    Add Payer
                </button>
            </h2>

            <h4 className="mb-3 font-heading text-3xl">In Network</h4>
            <div className=" items-center">
                <HorizontalLine styling="text-gray-dark" />

                {payers.map((payer, row) => (
                    <>
                        <div className="flex space-x-4">
                            <button
                                onClick={() =>
                                    setExpandedPayer(expandedPayer !== payer.id ? payer.id : null)
                                }>
                                {expandedPayer !== payer.id ? <FaChevronDown /> : <FaChevronUp />}
                            </button>
                            <p>{payer.name}</p>
                        </div>
                        {expandedPayer === payer.id && (
                            <PayerInfo
                                dmeReferralDestinations={dmeReferralDestinations}
                                payer={payer}
                                payerTypes={payerTypes}
                                serviceLocations={serviceLocations}
                                billingProviders={billingProviders}
                                setEditingPayer={setEditingPayer}
                                setModalOpen={setModalOpen}
                            />
                        )}
                        <HorizontalLine styling="text-gray-dark" />
                    </>
                ))}
            </div>
            <button
                className="pt-6 mb-8 border-b-2 border-gem-green font-bold"
                disabled={false}
                onClick={() => {
                    setViewNikoPayers(!viewNikoPayers);
                }}>
                {viewNikoPayers ? 'Hide NIKO Payers' : 'Show NIKO Payers'}
            </button>

            {viewNikoPayers && <NikoPayers />}

            {editingPayer !== null && (
                <EditPayerInformation
                    editingPayer={editingPayer}
                    payerTypes={payerTypes}
                    serviceLocations={serviceLocations}
                    billingProviders={billingProviders}
                    setEditingPayer={setEditingPayer}
                />
            )}

            {modalOpen === 'prior-auth-requirements' && (
                <EditPriorAuthRequirements
                    editingPayer={editingPayer}
                    modalOpen={modalOpen}
                    setEditingPayer={setEditingPayer}
                    setModalOpen={setModalOpen}
                />
            )}

            {modalOpen === 'providers' && (
                <EditPayerProviders
                    editingPayer={editingPayer}
                    modalOpen={modalOpen}
                    setEditingPayer={setEditingPayer}
                    setModalOpen={setModalOpen}
                />
            )}
        </>
    );
};

export default Payers;
