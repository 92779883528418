import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useGetPartnerOrganizationsQuery } from '../../../app/services/insurance';
import { convertEnumValueToDisplayString, formatPhoneNumber } from '../../../utils/constants';
import { useState } from 'react';
import { Pagination } from '..';
import { useEffect } from 'react';

const ORGS_PER_PAGE = 6;

const Partners = () => {
    const [page, setPage] = useState(0);
    const {
        data: partners,
        isLoading,
        isFetching,
        refetch,
    } = useGetPartnerOrganizationsQuery({
        paginated: true,
        skip: page * ORGS_PER_PAGE,
        take: ORGS_PER_PAGE,
    });

    const getReferralLink = (partner) => {
        const orgType = partner.organizationType === 'DENTAL' ? 'dental' : 'partner';
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
            return `https://portal-dev.gem.health/${orgType}/${partner.id}`;
        } else if (process.env.REACT_APP_ENVIRONMENT === 'local') {
            return `https://localhost:3001/${orgType}/${partner.id}`;
        } else {
            return `https://portal.gem.health/${orgType}/${partner.id}`;
        }
    };

    const rowVariants = {
        hide: { opacity: 0, x: '2%' },
        show: { opacity: 1, x: '0%' },
    };

    const getPartnerTypeColorClass = (partner) => {
        switch (partner.organizationType) {
            case 'DENTAL':
                return 'text-gem-green';
            case 'PAYER':
                return 'text-gem-purple';
            case 'INSURANCE_ALTERNATIVE':
                return 'text-gem-blue';
            default:
                return 'text-white';
        }
    };

    useEffect(() => {
        refetch();
    }, [refetch, page]);

    return isLoading ? (
        <p className="py-6 font-heading text-5xl">Loading...</p>
    ) : (
        <div className="py-6">
            <ul
                className={`${
                    isFetching || isLoading ? 'opacity-0' : 'opacity-100'
                } grid sm:grid-cols-2 lg:grid-cols-3 gap-4 transition`}>
                {partners?.results.length > 0 ? (
                    partners.results.map((partner, idx) => (
                        <motion.li
                            key={`partner-${partner.id}`}
                            className="relative space-y-2 rounded-lg border-purple/20 border-4 p-4 min-h-[390px]"
                            variants={rowVariants}
                            initial="hide"
                            animate="show"
                            exit="hide"
                            transition={{ ease: 'circOut', delay: 0.05 * idx }}>
                            <h3 className="mb-2 text-xl font-bold">{partner.name}</h3>
                            <div>
                                <p className="mb-2 text-sm font-bold">
                                    Type:{' '}
                                    <span className={getPartnerTypeColorClass(partner)}>
                                        {convertEnumValueToDisplayString(partner.organizationType)}
                                    </span>
                                </p>
                            </div>
                            {partner.isReferrer && (
                                <>
                                    <label className="text-sm font-bold">Referral Link:</label>
                                    <input
                                        className="w-full appearance-none border border-gray bg-transparent p-2 text-sm text-white"
                                        value={getReferralLink(partner)}
                                        onFocus={(event) => event.target.select()}
                                        readOnly
                                    />
                                </>
                            )}
                            <p className="text-sm break-all">
                                Website:{' '}
                                {partner.websiteUrl ? (
                                    <a href={partner.websiteUrl} className="link">
                                        {partner.websiteUrl}
                                    </a>
                                ) : (
                                    ' No Website provided'
                                )}
                            </p>
                            <p className="text-sm break-all">
                                Scheduling link:{' '}
                                <a href={partner.schedulingLink} className="link">
                                    {partner.schedulingLink}
                                </a>
                            </p>
                            <p className="text-sm break-all">
                                Email: <a href={`mailto:${partner.email}`}>{partner.email}</a>
                            </p>
                            <p className="text-sm">
                                Phone:{' '}
                                <a href={`tel:+1${partner.phone.replace(/[- )(]/g, '')}`}>
                                    {formatPhoneNumber(partner.phone)}
                                </a>
                            </p>
                            <p className="text-sm">
                                Address:{' '}
                                <span className="font-bold">
                                    {partner.address}, {partner.city}, {partner.state} {partner.zip}
                                </span>
                            </p>
                            <div className="!mt-2">
                                <Link
                                    to={`/admin/organizations/update/${partner.id}`}
                                    className="text-sm font-bold text-gem-green">
                                    Update
                                </Link>
                            </div>
                            {partner.organizationType === 'DME_PROVIDER' && (
                                <p className="text-sm">
                                    DME Referring Payers:{' '}
                                    <span className="font-bold">
                                        {partner.dmeReferringPayers
                                            .map((payer) => payer.name)
                                            .join(', ')}
                                    </span>
                                </p>
                            )}
                        </motion.li>
                    ))
                ) : (
                    <li className="font-heading text-3xl">No organizations found.</li>
                )}
            </ul>
            {partners?.metadata.total > ORGS_PER_PAGE && (
                <Pagination
                    page={page}
                    disabled={isFetching}
                    setPage={setPage}
                    totalPages={Math.floor(partners?.metadata.total / ORGS_PER_PAGE)}
                />
            )}
        </div>
    );
};

export default Partners;
